// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------

.btn {
    padding: 15px;
    cursor:pointer;
}



//legacy button brought in

.btn-primary {
    color: #fff;
    background-color: #002f65;
    border-color: #002f65;
}

.btn-primary-border {
    border-color: #002f65;
    color: #002f65;

    &:hover {
        border-color: #c4121d;
        color: #c4121d;
    }
}

.btn--arrow {
    position: relative;
    padding-right: 45px;
}

.btn--arrow img,
.btn--arrow svg {
    position: absolute;
    right: 15px;
    top: 14px;
    width: 12px;
    height: 22px;
    fill: #fff;
    transition: all 0.5s ease-in-out;
}


.btn-patient {
    background: #d3d3d3;
    border-color: #d3d3d3;
    color: #002f65 !important;
    font-size: 13px;
    position: relative;
    padding: 9px 12px 9px 12px !important;
    margin: 0;
    display: block;
    text-align: center;
    font-weight: bold;

    @include media-breakpoint-up(lg) {
        border-radius: 6px;
        margin: 6px 3px;
        display: inline-block;
        font-weight: normal;
        background: #eee4d2 !important;
        border-color: #eee4d2 !important;
        padding: 6px 12px 6px 12px !important;

        &:after {
            content: "\f105";
            display: inline-block;
            font-family: FontAwesome;
            font-style: normal;
            font-weight: normal;
            @include font-size(24px);
            line-height: 16px;
            vertical-align: text-bottom;
            position: relative;
            top: -1px;
            margin-left: 10px;
            font-weight: bold;
        }
    }
}

.blue-label {
    background: #002f65;
    padding: 10px 15px;
    display: flex;
    justify-content: space-between;
    //max-width: 430px;
}

.blue-label span {
    color: #fff;
    font-size: 18px;
}

.blue-label img {
    max-width: 20px;
    max-height: 20px;
}

.col-md-6:hover .blue-label,
.blue-label:active {
    background: #c4121d;
}

.btn-red {
    background: #c4121d;
    color: $white;
}

.btn-styleBorder {
    border-top-left-radius: 16px;
    border-bottom-right-radius: 16px;
    position: relative;
    padding-right: 45px;
    font-weight: bold;

    img,
    svg {
        position: absolute;
        right: 15px;
        top: 14px;
        width: 12px;
        height: 22px;
        fill: #fff;
        transition: all 0.5s ease-in-out;
        transform: rotate(90deg);
    }
}

// .btn-double-icon {
//     display: flex;
//     margin: 0 auto;
//     font-weight: bold;
//     align-items: center;
//     justify-content: space-between;

//     img,
//     svg {
//         width: 50px;
//         margin-left: 0px;
//         margin-right: 0px;

//         @include media-breakpoint-up(md) {
//             width: 30px;
//             margin-left: 30px;
//             margin-right: 10px;
//         }

//         @include media-breakpoint-up(lg) {
//             margin-left: 50px;
//         }

//     }

//     .btn-text {
//         margin-left: 10px;
//         margin-right: 10px;
//         font-size: 0.75rem;
//         text-transform: uppercase;
//         font-size: 1rem;

//         @include media-breakpoint-up(sm) {
//             font-size: 1rem;
//         }

//         @include media-breakpoint-up(md) {
//             font-size: 1rem;
//             margin-left: 0;
//             margin-right: 0;
//         }
//     }

// }

.btn-double-icon {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right:auto;
    margin-left:auto;

    img,
    svg {
        flex-shrink:0;
        &:last-of-type {
            width: 35px;
            flex-basis:35px;
        }

        &:first-of-type {
            width: 45px;
            flex-basis:45px;

            &.thumb {
                width: 80px;
                flex-basis:80px;
            }
        }

        @include media-breakpoint-up(md) {
            &:first-of-type {
                &.thumb {
                    width: 120px;
                    flex-basis:120px;
                }
            }
        }
    }

    .btn-text {
        padding-right: 20px;
        padding-left: 20px;
        font-weight: 700;
        flex-grow: 1;
        line-height: 20px;
        @media (max-width: 600px) {
            line-height: 14px;
        }
        @include media-breakpoint-down(md) {
            font-size: 13px;
        }
    }
}

.btn-strip {
    padding: 0;
    background: none;
}

.btn.btn--inactive {
	cursor: default;
}