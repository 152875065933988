.expandSection {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
    
    @include media-breakpoint-up(md) {
        padding-top: 3rem;
        padding-bottom: 3rem;
    }
    .container {
        position: relative;
    }

}

.expandSection-trigger {
    @include media-breakpoint-up(lg) {
        display: none;
    }
}

.expandSection-target {
    @include media-breakpoint-down(md) {
        display: none;
    }
}

.expandSection-collaspeTrigger {
    background: none;
    color: $midnightBlue;
    position: relative;
    margin-top: 1.5rem;
    margin-left: auto;
    margin-right: auto;
    display: block;
    @include media-breakpoint-up(md) {
        display: none;
    }
    svg {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        transform: rotate(-90deg);
        margin-left: -5px;
    }
}

.expandSection-label {
    text-transform: uppercase;
    @include font-size(14px);
    font-weight: 600;
    letter-spacing: 0.06rem;
    margin-bottom: .5rem;
    @include media-breakpoint-up(md) {
        margin-bottom: 2rem;
    }

}

.expandSection-image {
    padding: 1.5rem;
    margin: 0 auto;
    display: block;
    max-width: 100%;
    @include media-breakpoint-up(md) {
        padding: 0;
        position: absolute;
        right: 15px;
        top: 0;
        height: 150px;
    }
}
.expandSection-subTitle {
    @include font-size(24px);
    margin-bottom: 1.25rem;
}
.expandSection-title {
    @include font-size(37px);
    font-weight: bold;
    margin-bottom: 1.25rem;
}
.icon-padding {
    @include media-breakpoint-up(md) {
        padding-right: 25%;
    }
}

.circleInfo {
    text-align: center;

    .circleInfo-circle {
        background-color: $midnightBlue;
        color: $white;
        border-radius: 50%;
        width: 100px;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;

    }
    .circleInfo-number {
        @include font-size(36.8px);
        font-weight: bold;
        line-height: 1;

    }
    .circleInfo-percent {
        position: relative;
        top: -0.45rem;

    }
    .circleInfo-innerLabel {
        @include font-size(12px);
        font-weight: bold;

    }
    .circleInfo-label {
        @include font-size(12px);
        margin-top: 6px;
        color: $midnightBlue;
    }
}

.callout {
    margin: 1rem auto;
    display: inline-block;
    border-left: 3px solid $midnightBlue;
    border-right: 3px solid $midnightBlue;
    padding: 1rem;
    font-family: 'adelle-condensed';
    position: relative;
    font-style: oblique;
    &:after {
        content: '';
        position: absolute;
        width: 10px;
        height: 100%;
        border-top: 3px solid $midnightBlue;
        border-bottom: 3px solid $midnightBlue;
        left: -1px;
        top: 0;
    }
    &:before {
        content: '';
        position: absolute;
        width: 10px;
        height: 100%;
        border-top: 3px solid $midnightBlue;
        border-bottom: 3px solid $midnightBlue;
        right: -1px;
        top: 0;
    }
    .calloutTitle {
        color: $colorBrandRed;
        @include font-size(20px);
        font-weight: 500;
        font-weight:  bold;
        sup {
            top: -.4em;
        }
    }
}