//aa360 styles from nuelasta
$colorBrandBlueAA360:#00a1e2;

.bg-blueLight {
    background-color: #ddf0fa !important;
}

.w-207 {
    width: 207px;
}

.w-190 {
    width: 190px;
}
.w-195 {
    width: 195px;
}

.outline {
    border: 3px solid #046db5;
    padding: 0.375rem 1.875rem;
    border-radius: 6px;
}

.color-aa360 {
    color: $colorBrandBlueAA360 !important;
}

.color-aa360-2 {
    color:  #003c64 !important;
}

.card-bg {
    background: url(__("/img/content-images/first-step-card.png"));
    background-repeat: no-repeat;
    background-size: 400px;
    background-position: center center;
    min-height: 300px;

    @media (min-width: 768px) {
        min-height: 550px;
        background-size: 100%;
    }
}


.aa360,
.aa360-patientResources {
    position: relative;

    overflow: hidden;

    p, ul {
        color: #202021;
    }

    img {
        max-width: 100%;
    }

    h2 {
        font-size: 1.5625rem;
        font-weight: 400;
        line-height: 1.875rem;

        @include media-breakpoint-up(md) {
            font-size: 2.125rem;
            line-height: 2.8125rem;
            letter-spacing: 0.04rem;
            
        }
    }

    .color-blue {
        color: #046db5;
    }

    .btn {
        @include font-size(16px);
        font-weight: 500;
        line-height: 1.3125;
        cursor: pointer;
        padding: .5rem 1.25rem;
        border-radius: 6px;
        &:focus {
            color: $white;
            text-decoration: none;
        }
    }

    //aa360 styles from nuelasta
    .btn-tertiary {
        background: #66c7ee;
        border-color: #66c7ee;
        color: $white;
    }
    

    .btn-outline-light,
    .btn-outline-primary {
        border-width: .15625rem;
    }

    .btn-outline-primary {
        @include font-size(12px);
        color: #2870c9;
        border-color: #2870c9;
        background: none;
        font-weight: 500;
        line-height: 1rem;
        border-width: 3px;

        &:hover, &:focus {
            background: none;
            border-color: #2870c9;
            color: #2870c9;
        }
    }
    .btn-outline-light {
        border-width: 3px;
        font-weight: 600;
        &:hover, &:focus {
            background: none;
            border-color: $white;
            color: $white;
        }
    }


    .btn-download,
    .btn-download--blue {
        display: inline-flex;
        align-items: center;
        justify-content: space-between;

        &:after {
            content: '';
            background-image: url(../img/content-images/download-white.svg);

            display: block;

            width: 34px;
            height: 33px;
            background-repeat: no-repeat;
            background-position: center center;
        }
    }

    .btn-download--blue {
        &:after {
            width: 24px;
            height: 32px;
            background-image: url(../img/content-images/download-blue.png);
            margin-left: 20px;
            margin-top: -.875rem;
            margin-bottom: -.875rem;
        }
    }
}

.aa360-logo {
    max-width: 215px;
}

.aa360-graphic {
    pointer-events: none;

    position: absolute;
    top: 0;
    right: -120px;

    transform: scale(2) translate(25%, -8%);

    z-index: 0;

    @include media-breakpoint-up(md) {
        width: 80%;
        right: 0;
        transform: translate(15%, -25%);
    }
}

.aa360-resources {
    position: relative;
    color: $white;
    &:after {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        background: #00a1e2;
        opacity: 0.6;;
        top: 0;
        z-index: -1;
    }
    p, ul {
        color: $white;
    }

    h2,
    h3,
    h4,
    h5,
    h6 {
        color: inherit;
    }

    h6 {
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.875;
    }
}

.aa360footer {
    background: linear-gradient(90deg, #00A1E2, #2870C9);
    color: $white;
    text-align: center;
    color: $white;
    padding:24px;
    >*:Last-child {
        margin-bottom:0;
    }
    &-heading {
        color:$white;
        font-weight:700;
    }

    &-btns {
        
        margin-right: auto;
        margin-left: auto;
        margin-bottom:20px;
        @include media-breakpoint-up(md) {
            display: inline-flex;
        }
    }

    &-btn {
        color: $white;
        border: 2px solid;
        padding: 0.5rem;
        border-radius: 6px;
        
        align-items: center;
        justify-content: center;
        flex-direction:column;
        &:hover {
            color: $white;
            border-color: $white;
        }
        
        @include media-breakpoint-up(md) {
            margin-right:.5em;
            display: inline-flex;
        }
        &:last-of-type {
            margin-right:0;
        }
    }
}

.connect-with-us {
	font-size: 1.125rem;
	text-align: left;
  line-height: 1.5;
  font-weight: 500;
  letter-spacing: 0.03rem;
}

.aa360Title {
    font-size: 1.875rem;
    line-height: 2.125rem;
    color: #2870c9;
    @include media-breakpoint-up(md) {
        font-size: 2.5rem;
        line-height: 2.75rem;
    }
}