.heroSlider {
    overflow: hidden;
    position: relative;
    background-color: $black;
    &:before {
        position: relative;
        padding-bottom: 410px;
        content: '';
        display: block;
        @include media-breakpoint-up(sm) {
            padding-bottom: 86%;
        }
        @include media-breakpoint-up(md) {
            padding-bottom: 36%;
        }
        
    }
    
}
.heroSlide {
    position: absolute;
    width: 100%;
    top: 0;
    z-index: 9;
    
}
.heroSlide:nth-child(2) {
    z-index: 8;
}
.heroSlideInner {
    position: relative;
    &:before {
        position: relative;
        padding-bottom: 410px;
        content: '';
        display: block;
        @include media-breakpoint-up(sm) {
            padding-bottom: 86%;
        }
        @include media-breakpoint-up(md) {
            padding-bottom: 36%;
        }
    }
}
.heroSlideImageHolder {
    position: absolute;
    width: 100%;
    bottom: 0;
    z-index: -1;
    top: 0;
}

.heroSlideImage {
    max-width: 100%;
        width: 100%;
    @include media-breakpoint-up(md) {
        max-width: 100%;
        width: 100%;
    }
}

.heroSlideContentHolder {
    opacity: 0;
    position: absolute;
    top:0;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    align-items: flex-end;
    padding-top: 2rem;
    padding-bottom: 2rem;
    @include media-breakpoint-up(md) {
        
        align-items: center;
    }
    .btn {
        color: $white !important;
    }
}
.heroSlideContentHolder-offset {
    @include media-breakpoint-down(sm) {
        min-height: 164px;
    }
    @include media-breakpoint-up(md) {
        padding-left: 38%;
    }
}
.heroSlideTitle {
    color: $white;
    @include font-size(18px);
    margin-bottom: 1.5rem;
    @include media-breakpoint-up(lg) {
        @include font-size(37px);
        margin-bottom: 1.5rem;
        line-height: 1.25;
    }
}
.heroSlideSubTilte {
    color: $white;
    @include font-size(12px); 
    line-height: normal;
    margin-bottom: 0.75rem; 
    font-weight: bold;      
    @include media-breakpoint-up(lg) {
        font-weight: normal;
        @include font-size(14px);
        margin-bottom: 1rem;
        line-height: 1.5;
    }
}
a.heroSlideCTA {
    padding-left: 0.75rem;
    padding-right: 45px ;
    @include font-size(14px);
    @include media-breakpoint-down(sm) {
        display: block;
        text-align: center;
    }
    @include media-breakpoint-up(md) {
        @include font-size(18px);
    }
}
.heroJumpbar {
    @include media-breakpoint-up(md) {
        position: relative;
        top: -16px;
        z-index: 10;
    }
    .jumpbar {
        margin-left: auto;
        margin-right: auto;
        justify-content: center;
    }
}