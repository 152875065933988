.nplate-targets-megakaryocyte-chart {
    text-align: center;
    margin-left: 15px;
    margin-right: 15px;
}

.nplate-targets-megakaryocyte-chart .col-lg-4 {
    padding-right: 0;
    padding-left: 0;
    margin-right: 0;
    margin-left: 0;
}

.nplate-targets-megakaryocyte-chart img {
    width: 100%;
    border-right: 2px solid white;
    @media (min-width: 1199px) {
        height: 164px;
    }
}

@media (max-width: 767px) {
    .nplate-targets-megakaryocyte-chart img {
        border-right: none;
    }
}

.nplate-targets-megakaryocyte-chart img.red-arrow {
    position: relative;
    left: 0;
    right: 0;
    height: 20px;
    width: 10px;
    border: none;
}

@media (max-width: 1199px) {
    .nplate-targets-megakaryocyte-chart img.red-arrow {
        position: relative;
        left: 0;
        bottom: 0;
        height: 20px;
    }
}

.nplate-targets-megakaryocyte-chart img.no-border {
    border-right: none;
}

.nplate-targets-megakaryocyte-chart .blue-background {
    background: #002F65;
    color: #ffffff;
    //font-size: 1.6rem;
    @include font-size(16px);
    padding: 10px;
    font-weight: 600;
}

.nplate-targets-megakaryocyte-chart .beige-background {
    background: #b2b3b5;
    color: #002F65;
    //font-size: 1.6rem;
    @include font-size(16px);
    padding: 10px;
    font-weight: 600;
}

.nplate-targets-megakaryocyte-chart .light-beige-background {
    background: #e0e0e0;
    color: #002F65;
    //font-size: 1.3rem;
    @include font-size(13px);
    padding: 10px;
    border-right: 2px solid white;
    padding-top: 0rem;
    padding-bottom: 40px;
}

.nplate-targets-megakaryocyte-chart .cyan-background {
    background: #D8DFE7;
    color: #000000;
    //font-size: 1.3rem;
    @include font-size(13px);
    padding: 10px;
    padding-top: 0rem;
    padding-bottom: 40px;
    border-right: 2px solid white;
}

.nplate-targets-megakaryocyte-chart .cyan-background:last-of-type {
    border-right: none;
}

.nplate-targets-megakaryocyte-chart .header {
    margin-bottom: 2px;
}

.nplate-targets-megakaryocyte-chart .header .beige-background {
    border-right: 2px solid #ffffff;
}

.nplate-targets-megakaryocyte-chart .footer {
    padding-top: 0;
    background: #F9F3E9;
}

.nplate-targets-megakaryocyte-chart .footer .col-md-4 {
    padding-left: 5px;
    padding-right: 5px;
}

.nplate-targets-megakaryocyte-chart .footer i {
    color: #c4121d;
}

.nplate-targets-megakaryocyte-chart.mobile .header {
    margin-bottom: 2px;
    //font-size: 3.6rem;
    @include font-size(36px);
    padding: 20px 0;
}

.nplate-targets-megakaryocyte-chart.mobile .footer {
    margin-bottom: 10px;
    //font-size: 2.4rem;
    @include font-size(24px);
    padding: 0 0 30px 0;
    margin-bottom: 30px;
    border-right: none;
}

.nplate-targets-megakaryocyte-chart.mobile img.red-arrow {
    height: 40px;
    width: 20px;
}