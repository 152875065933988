#dosing-calc {
    padding-top: 55px;
    padding-bottom: 55px;
    padding-left: 20px;
    padding-right: 20px;
}

#dosing-calc .container {
    background: #fff;
    border: 1px solid #000;
    padding: 5px 55px 15px;
}

@media (max-width: 767px) {
    #dosing-calc {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    #dosing-calc .container {
        background: #fff;
        padding: 0 1.5rem;
    }
}

.dosing-calc-head {
    padding-top: 15px;
}
.dosing-calc-desktop {
    display: block;
}

.dosing-calc-mobile {
    display: none;
}

@media (max-width: 767px) {
    .dosing-calc-desktop {
        display: none;
    }

    .dosing-calc-mobile {
        display: block;
    }
}

.dosing-calc-bookmark {
    color: #c4121d;
    position: relative;
    text-align: right;
    justify-content: flex-end;
    align-items: center;
    text-decoration: none;
    display: flex;
}

.dosing-calc-bookmark.mobile {
    margin-bottom: 20px;
}

.dosing-calc-bookmark span {
    color: #c4121d;
    display: block;
    background: currentColor;
    height: 20px;
    width: 20px;
    margin-left: 10px;
    border-radius: 3px 3px 0 0;
    position: relative;
}

@media (min-width: 992px) {

    .dosing-calc-bookmark:hover,
    .dosing-calc-bookmark:hover span {
        color: #002f64;
    }
}

.dosing-calc-bookmark span:before,
.dosing-calc-bookmark span:after {
    position: absolute;
    height: 0;
    width: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    display: block;
    content: '';
    bottom: -10px;
}

.dosing-calc-bookmark span:before {
    border-left: 10px solid currentColor;
    border-right: 10px solid transparent;
    left: 0;
}

.dosing-calc-bookmark span:after {
    border-right: 10px solid currentColor;
    border-left: 10px solid transparent;
    left: 0;
}

@media (min-width: 768px) {
    .dosing-calc-bookmark.mobile {
        display: none !important;
    }
}

@media (max-width: 767px) {
    .dosing-calc-bookmark.desktop {
        display: none !important;
    }
}

.dosing-calc-blue-box {
    padding: 19px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #002F65;
    background: rgba(0, 47, 101, .1);
    margin-bottom: 15px;
    border-radius: 5px;
}

@media (max-width: 767px) {
    .dosing-calc-blue-box:first-child {
        margin-top: 15px;
    }
}

.dosing-calc-blue-box p {
    text-align: center;
}

.dosing-calc-blue-box strong {
    text-align: center;
    margin-bottom: 1.5rem;
}

.dosing-calc-blue-box p:last-child {
    margin: 0;
}

.dosing-calc-blue-box hr {
    margin-top: 0;
    margin-bottom: 1.5rem;
    border: 0;
    height: 1px;
    width: 80%;
    background: #002F65;
}

.dosing-calc-notes h2 {
    //font-size: 2rem;
    @include font-size(20px);
}

.dosing-calc-body .color--red {
    margin-top: 25px;
    display: block;
}

.dosing-calc-body th {
    text-align: center;
}

.dosing-calc-bookmark span.dosing-save-icon {
    background: url(__("/img/content-images/icon-save.png"));
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 24px;
    width: 24px;
    border-radius: 0;
}

.dosing-save-icon:before,
.dosing-save-icon:after {
    display: none !important;
}

.calculator {
    background-color: #f9f3e9;
    padding: 25px;
    border-radius: 4px;
}


// html {
//     position: relative;
//     min-height: 100%;
// }

// body {
//     margin-bottom: 60px;
//     font-family: "Arial", sans-serif, sans-serif;
//     font-size: 18px;
//     font-weight: 400;
//     line-height: 1.7;
//     max-width: 850px;
// }

// h1 {
//     font-family: "Arial", sans-serif;
//     color: #c4121d;
//     font-size: 24px;
//     font-weight: 400;
// }

// h2 {
//     font-family: "Arial", sans-serif;
//     color: #002f65;
//     font-size: 20px;
//     font-weight: bold;
// }

// p {
//     font-size: 16px;
//     color: #222222;
//     font-family: "Arial", sans-serif;
//     line-height: 1.2;
// }

// a {
//     color: #c4121d;
//     text-decoration: none;
//     font-weight: bold;
// }

a:hover {
    color: #002f65;
}

.calculator {
    background-color: #f9f3e9;
    padding: 25px;
    border-radius: 4px;
}

.calculator form {
    @include media-breakpoint-up(lg) {
        display: -ms-flexbox;
        display: flex;
    }
}

.calculator form input,
.calculator form select {
    border: 1px solid #002f65;
    border-radius: 3px;
    height: 35px;
    font-size: 16px;
    color: #002f65;
}

.calculator form label {
    display: block;
    line-height: 1.2;
}

.calculator form label strong {
    line-height: 0;
}

.calculator form .step-1 {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-bottom: 2.25rem!important;
    
    @include media-breakpoint-up(lg) {
        width: 70%;
        margin-bottom: 0;
    }
    
}

.calculator form .step-1 label {
    display: block;
    @include media-breakpoint-up(lg) {

    }
}
.calculator form .step-1 > label {

    @include media-breakpoint-up(lg) {
        width: 60%;
    }
}

.calculator form .step-1 .field-container {
    width: 100%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-align: center;
    align-items: center;
}

.calculator form .step-1 .field-container input[type=number] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: white;
    display: inline-block;
    width: 80%;
    padding: 0 15px;
    -moz-appearance: textfield;
    @include media-breakpoint-up(lg) {
        width: 50%;
    }
}

.calculator form .step-1 .field-container input[type=number]::-webkit-inner-spin-button,
.calculator form .step-1 .field-container input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.calculator form .step-1 .field-container fieldset {
    display: inline-block;
    width: 100%;
    margin: 0 0 0 15px;
    border: none;
    @include media-breakpoint-up(lg) {
        width: 40%;
    }
}

.calculator form .step-1 .field-container fieldset input[type=radio] {
    display: inline-block;
    margin: 0;
    padding: 0;
    height: auto;
    width: auto;
}

.calculator form .step-1 .field-container fieldset label {
    display: inline-block;
    padding: 0;
    font-weight: bold;
    margin: 0 15px 0 5px;
    position: relative;
    top: 0px;
    font-size: 16px;
}

.calculator form .step-1 span.error {
    display: block;
    color: #c4121d;
    font-size: 12px;
}

.calculator form .step-1 input.error {
    color: #c4121d;
    border: 1px solid #c4121d;
}

.calculator form .step-2 {
    display: inline-block;
    margin-bottom: 2.25rem!important;
    
    @include media-breakpoint-up(lg) {
        width: 40%;
        margin-bottom: 0;
    }
}

.calculator form .step-2 label {
    line-height: 1.2;
    margin-bottom: 10px;
}

.calculator form .step-2 select {
    display: inline-block;
    width: 60%;
    padding: 0 15px;
    background-color: white;
    -webkit-appearance: none;
    -moz-appearance: none;
    margin-right: 5px;
    background: url(__("/img/content-images/selectArrow.svg")) no-repeat white;
    background-size: 20px 20px;
    background-position-x: calc(100% - 15px);
    background-position-y: 7px;
}

.calculator form .step-2 select::-ms-expand {
    display: none;
}

.calculator form .step-2 span {
    font-size: 16px;
    font-weight: bold;
    color: #002f65;
}

.step-3 {
    margin-top: 10px;
}

.step-3 span,
.step-4 span {
    font-size: 16px;
    color: #002f65;
    line-height: 1.2;
    display: block;
    margin-bottom: 0.5rem;
}

.step-3 span strong,
.step-4 span strong {
    font-size: 20px;
    color: #c4121d;
}

.step-3 .result-container,
.step-4 .result-container {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-direction: column;
    flex-direction: column;
    background-color: rgba(0, 47, 101, 0.1);
    border-radius: 4px;
    border: 1px solid #002f65;
    margin-bottom: 5px;
    min-height: 150px;
}

.step-3 .result-container .injection-volume.hidden,
.step-4 .result-container .injection-volume.hidden {
    display: none;
}

.step-3 .result-container .pi-warning,
.step-4 .result-container .pi-warning {
    width: 95%;
    text-align: center;
    padding: 25px;
}

.step-3 .result-container .pi-warning.hidden,
.step-4 .result-container .pi-warning.hidden {
    display: none;
}

.step-3 .result-container span.result,
.step-4 .result-container span.result {
    color: #002f65;
    display: block;
    font-size: 36px;
    top: 0;
}

.step-3 .result-container span,
.step-4 .result-container span {
    font-weight: bold;
    display: inline;
    margin-bottom: 0;
    position: relative;
    top: 0;
}

.step-3 .result-container .total-dose,
.step-3 .result-container .injection-volume,
.step-4 .result-container .total-dose,
.step-4 .result-container .injection-volume {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 100%;
    height: 150px;
}

.step-3 .result-container .total-dose .result-inner-container,
.step-3 .result-container .injection-volume .result-inner-container,
.step-4 .result-container .total-dose .result-inner-container,
.step-4 .result-container .injection-volume .result-inner-container {
    width: 100%;
    height: 100%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
}

.step-3 .result-container .total-dose .result-inner-container.left-container,
.step-3 .result-container .injection-volume .result-inner-container.left-container,
.step-4 .result-container .total-dose .result-inner-container.left-container,
.step-4 .result-container .injection-volume .result-inner-container.left-container {
    border-right: 4px solid #f9f3e9;
}

.step-3 .result-container .total-dose .result-inner-container div,
.step-3 .result-container .injection-volume .result-inner-container div,
.step-4 .result-container .total-dose .result-inner-container div,
.step-4 .result-container .injection-volume .result-inner-container div {
    text-align: center;
    padding: 25px 0;
}

.step-3 .result-container .total-dose .result-inner-container div hr,
.step-3 .result-container .injection-volume .result-inner-container div hr,
.step-4 .result-container .total-dose .result-inner-container div hr,
.step-4 .result-container .injection-volume .result-inner-container div hr {
    width: 50%;
    border: none;
    border-top: 1px solid #002f65;
    margin: 4px auto;
}

.step-3 .result-container .total-dose .result-inner-container div span.formula,
.step-3 .result-container .injection-volume .result-inner-container div span.formula,
.step-4 .result-container .total-dose .result-inner-container div span.formula,
.step-4 .result-container .injection-volume .result-inner-container div span.formula {
    font-weight: normal;
}

.step-4 {
    margin-top: 25px;
    color: #002f65;
}

label {
    color: #002f65;
    font-size: 16px;
}

label strong {
    font-size: 20px;
    color: #c4121d;
}

@media screen and (max-width: 750px) {
    .calculator form {
        -ms-flex-direction: column;
        flex-direction: column;
    }

    .calculator form .step-1,
    .calculator form .step-2 {
        display: block;
        width: 100%;
        margin-bottom: 15px;
    }

    .calculator form .step-1 .field-container input[type=number] {
				width: 62%;
				min-width: 158px;
    }

    .step-4 img {
        display: none !important;
    }
}

@media screen and (max-width: 650px) {
    .calculator .step-3 .result-container {
        min-height: 280px;
    }

    .calculator .step-3 .result-container .total-dose,
    .calculator .step-3 .result-container .injection-volume {
        -ms-flex-direction: column;
        flex-direction: column;
        width: 100%;
        height: auto;
    }

    .calculator .step-3 .result-container .total-dose .result-inner-container,
    .calculator .step-3 .result-container .injection-volume .result-inner-container {
        width: 100%;
    }

    .calculator .step-3 .result-container .total-dose .result-inner-container.left-container,
    .calculator .step-3 .result-container .injection-volume .result-inner-container.left-container {
        border: 0;
        border-bottom: 4px solid #f9f3e9;
    }
}

@media screen and (max-width: 400px) {
    .calculator form .step-1 .field-container {
        -ms-flex-direction: column;
        flex-direction: column;
        -ms-flex-align: start;
        align-items: flex-start;
    }

    .calculator form .step-1 .field-container input[type=number] {
        margin-top: 10px;
        width: 62%;
    }

    .calculator form .step-1 .field-container fieldset {
        padding: 10px 0;
        margin-left: 0;
        width: 100%;
    }
}