// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

/*
 * black and white for mixing
 */
 $colorWhite: #fff !default;
 $colorBlack: #000 !default;
 /*
 * Define base brand colors and gray variables here and then add them to the map below
 */

 $colorBrandRed: #f26f1e;
 $colorBrandBlueLight: #e1f3f3;

 $yellow: #fec32a;
 $pink: #e64a80;
 
 $colorGrayLighter: #e6e5e5;

//nplate colors
$alto: #D3D3D3;
$midnightBlue: #002f65;
$colorBrandBlue: #002f65;
$colorBrandRed: #c4121d;
$colorGray: #b6b6b6;
$colorGray2: #6d6d6d;
$colorGray3: #d1d1d1;
$colorGrayDark: #222222;
$colorBlue2: #4F587E;

 /*
 * Place brand colors and gray in map for shades
 * Helper classes are being generated by this sass map in styles/base/_helpers.scss
 * as well as populating in pug/styleguide.pug
 * USAGE:
	 div {
		 color: palette(colorGray,darker);
		 background-color: palette(colorBrandBlue,lightest);
		 border: 1px solid palette(colorBrandBlue,base);
	 }
 */
 $palettes: (

	colorBrandBlue: (
		lightest : mix($colorWhite, $midnightBlue, 85%),
		lighter  : mix($colorWhite, $midnightBlue, 55%),
		light    : mix($colorWhite, $midnightBlue, 25%),
		base     : $colorBrandRed,
		dark     : mix($colorBlack, $midnightBlue, 25%),
		darker   : mix($colorBlack, $midnightBlue, 50%),
		darkest  : mix($colorBlack, $midnightBlue, 75%)
	),
	 colorBrandRed: (
		 lightest : mix($colorWhite, $colorBrandRed, 85%),
		 lighter  : mix($colorWhite, $colorBrandRed, 55%),
		 light    : mix($colorWhite, $colorBrandRed, 25%),
		 base     : $colorBrandRed,
		 dark     : mix($colorBlack, $colorBrandRed, 25%),
		 darker   : mix($colorBlack, $colorBrandRed, 50%),
		 darkest  : mix($colorBlack, $colorBrandRed, 75%)
	 ),
	 colorGray: (
		 beyondlightest : mix($colorWhite, $colorGray, 95.4%),
		 lightest : mix($colorWhite, $colorGray, 77.1%),
		 lighter  : mix($colorWhite, $colorGray, 55%),
		 light    : mix($colorWhite, $colorGray, 25%),
		 base     : $colorGray,
		 dark     : mix($colorBlack, $colorGray, 25%),
		 darker   : mix($colorBlack, $colorGray, 50%),
		 darkest  : mix($colorBlack, $colorGray, 75%)
	 )
 );
 
 
 /*
  * overall body font color
  */
 $colorText: $colorGray;
 
 /*
  * overall body font color
  */
 $colorLinks:map-get(map-get($palettes, 'colorBrandBlue'), 'base');
 $colorLinksHover:map-get(map-get($palettes, 'colorBrandRed'), 'base');
 /*
  * Generic border color for consistency
  */
 $colorBorder:map-get(map-get($palettes, 'colorGray'), 'lighter');
 /*
 * Global max-width
 */
 $maxWidth:1140px;
 
 /*
 * bootstrap overrides
 */
 $container-max-widths: (
   sm: $maxWidth - 3px,
   md: $maxWidth - 2px,
   lg: $maxWidth - 1px,
   xl: $maxWidth
 );
 
 //font-family: 'Barlow', sans-serif;
 //font-family: 'Barlow Condensed', sans-serif;
 //font-family: 'Barlow Semi Condensed', sans-serif;
 $font-family-base: Arial, Helvetica, sans-serif;
 $headings-font-family: Arial, Helvetica, sans-serif;
 $headings-font-weight: bold;


 
 // Spacing
 //
 // Control the default styling of most Bootstrap elements by modifying these
 // variables. Mostly focused on spacing.
 // You can add more entries to the $spacers map, should you need more variation.
 $spacer: 1rem !default;
 $spacers: ( 0: 0,
	 1: ($spacer * .25),
	 2: ($spacer * .5),
	 3: $spacer, 4: ($spacer * 1.5),
	 5: ($spacer * 1.75),
	 6: ($spacer * 2),
	 7: ($spacer * 2.25),
	 8: ($spacer * 2.5),
	 9: ($spacer * 3),
	 10: ($spacer * 3.25),
	 11: ($spacer * 3.5),
	 12: ($spacer * 3.75),
	 13: ($spacer * 4),
	 14: ($spacer * 4.25),
	 15: ($spacer * 4.5),
	 16: ($spacer * 4.75),
	 17: ($spacer * 5),
	 18: ($spacer * 5.25),
	 19: ($spacer * 5.25),
	 20: ($spacer * 5.5),
	 21: ($spacer * 5.75),
	 22: ($spacer * 6),
	 23: ($spacer * 6.25),
	 24: ($spacer * 6.5),
	 25: ($spacer * 6.75),
	 26: ($spacer * 7),
	 27: ($spacer * 7.25),
	 28: ($spacer * 7.5),
	 29: ($spacer * 7.75),
	 30: ($spacer * 8))
	  !default;
 
 // $input-btn-padding-y:null;
 // $input-btn-padding-x:null;
 $btn-border-radius:0px;
 $btn-box-shadow:none;
 $btn-focus-box-shadow:none;
 $btn-active-box-shadow:none;
 $btn-primary-bg: transparent;
 $btn-primary-border-color: transparent;
 $btn-secondary-color:$colorWhite;
 $btn-secondary-bg: transparent;
 $btn-secondary-border-color: transparent;
 //style main site elements
 
 // Main notification variables
 $notifyMain-background: white;
 $notifyMain-color: inherit;
 $notifyMain-heading-color: $midnightBlue;
 $notifyMain-heading-size: 14px;
 $notifyMain-toggle-color: $colorWhite;
 $notifyMain-close-color: $colorBrandRed;
 $notifyMain-close-size: 26px;
 
 // Main header variables
 $headerMain-navUtilMobile-border-color:$colorWhite;
 $headerMain-util-background: $colorGrayLighter;
 
 $headerMain-navUtil-size: 14px;
 $headerMain-navUtilLink-color: $colorWhite;
 $headerMain-navUtilLink-active-color: white;
 
 
 $headerMain-ctaBtn-background: map-get(map-get($palettes, 'colorBrandBlue'), 'base');
 $headerMain-ctaBtn-color:$colorWhite;
 $headerMain-ctaBtn-size:13px;
 $headerMain-ctaBtn-border-radius:25px;
 
 $headerMain-search-background:$colorWhite;
 $headerMain-search-border-radius:25px;
 $headerMain-search-height:30px;
 
 $headerMain-searchToggle-color:white;
 $headerMain-searchToggle-active-color: map-get(map-get($palettes, 'colorBrandRed'), 'base');
 $headerMain-searchToggle-size:18px;
 
 $headerMain-searchBox-size: 14px;
 
 $headerMain-main-background: $colorWhite;
 
 $headerMain-navMainLink-mobile-active-color:$colorWhite;
 $headerMain-navMainLink-mobile-active-background:map-get(map-get($palettes, 'colorBrandRed'), 'base');
 
 $headerMain-navMainLink-color: $midnightBlue;
 $headerMain-navMainLink-active-color: map-get(map-get($palettes, 'colorBrandRed'), 'base');
 
 $headerMain-subContent-mobile-background:$colorWhite;
 $headerMain-subContent-background:rgba(#ffffff, 1);
 
 // $headerMain-subMainLink-color: map-get(map-get($palettes, 'colorBrandBlue'), 'base');
 // $headerMain-subMainLink-size: 18px;
 // $headerMain-subMainLink-active-color: map-get(map-get($palettes, 'colorBrandRed'), 'base');
 
 $headerMain-subMainLink-mobile-color: map-get(map-get($palettes, 'colorBrandRed'), 'base');
 $headerMain-subMainLink-color: map-get(map-get($palettes, 'colorBrandBlue'), 'base');
 $headerMain-subMainLink-size: 1.125rem;
 $headerMain-subMainLink-active-color: map-get(map-get($palettes, 'colorBrandRed'), 'base');
 
 $headerMain-subcollateralCta-color:$colorWhite;
 $headerMain-subcollateralCta-hover-color:$colorWhite;
 $headerMain-subcollateralCta-size:18px;
 $headerMain-subcollateralCta-background:map-get(map-get($palettes, 'colorBrandRed'), 'base');
 
 $notifyTray-toggle-color:map-get(map-get($palettes, 'colorBrandRed'), 'base');
 $notifyTray-toggle-hover-color:map-get(map-get($palettes, 'colorBrandRed'), 'base');
 
 // Main footer variables
 $footerMain-background: $colorGray;
 $footerMain-color: $colorWhite;
 $footerMain-size: 14px;
 
 $footerMain-main-border-color:map-get(map-get($palettes, 'colorBrandRed'), 'base');
 
 $footerMain-navLink-color:$colorWhite;
 $footerMain-navUtilLink-hover-color:$colorWhite;
 $footerMain-nav-size:14px;
 
 $footerMain-topLink-background: $colorGrayLighter;
 $footerMain-topLink-color:$colorGray;
 $footerMain-topLink-hover-color:$colorBrandRed;
 
 $stdpadding: 2rem;
 $stdpaddingMoblie: 1.5rem;
 
 $smpadding: 1rem;
 $smpaddingMoblie: 1rem;
 
 //override default bootstrap font size
 $font-size-h1: 2.875rem;
 $font-size-h2: 1.75rem;
 $font-size-h3: 1rem;
 $font-size-h4: 1.25rem;
 $font-size-h5: 1rem;
 $font-size-h6: 1.25rem;
 //extra H styles
 $font-size-h7: 1.25rem;
 $font-size-h8: 0.875rem;
 
 //mobile H Styles
 $font-mobile-size-h1: 1.75rem;
 $font-mobile-size-h2: 1.3125rem;
 $font-mobile-size-h3: 0.875rem;
 $font-mobile-size-h4: 0.875rem;
 $font-mobile-size-h5: 0.875rem;
 $font-mobile-size-h6: 0.875rem;
 //extra H mobile styles
 $font-mobile-size-h7: 1rem;
 $font-mobile-size-h8: 0.625rem;
 
 $grid-columns:      12;
 $grid-gutter-width-base: 30px;

 
 $grid-gutter-widths: (
   xs: 30px,
   sm: 30px,
   md: $grid-gutter-width-base, // 30px
   lg: $grid-gutter-width-base, // 30px
   xl: $grid-gutter-width-base  // 30px
 )
 