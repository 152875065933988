.symptoms-chart {
    margin-bottom: 20px;
}
.symptoms-chart .heading-line {
    position: relative;
    text-align: center;
    width: 100%;
}
.symptoms-chart .heading-line:before {
    background-color: #c4121d;
    display: block;
    content: " ";
    height: 4px;
    position: absolute;
    top: 8px;
    width: 100%;
    z-index: 0;
}
.symptoms-chart .heading-line h2 {
    background: #FFF;
    color: #c4121d;
    display: inline;
    //font-size: 2rem;
    @include font-size(20px);
    font-weight: bold;
    margin: 0 auto;
    padding: 0 10px;
    position: relative;
    text-transform: uppercase;
    z-index: 10;
}
.symptoms-chart .symptom-section {
    margin-bottom: 5px;
    padding: 10px 0;
    text-align: center;
}
.symptoms-chart .symptom-section h2 {
    //font-size: 2rem;
    @include font-size(20px);
    font-weight: bold;
    margin: 0;
    text-transform: uppercase;
}
.symptoms-chart .symptom-section p {
    //font-size: 1.4rem;
    @include font-size(14px);
    margin: 0 auto;
    width: 75%;
}
.symptoms-chart .symptom-row {
    overflow: hidden;
}
.symptoms-chart .symptom-row .symptom-item {
    background: $colorGray3; 
    //background-image: url("/img/content-images/chart-arrow-red.svg");
    background-image: url(__("/img/content-images/chart-arrow-red.svg"));
    background-position: top center;
    background-repeat: no-repeat;
    border-bottom: 5px solid #FFF;
    padding: 10px;
    padding-top: 25px;
    text-align: center;
    width: 100%;
}
@media only screen and (min-width: 992px) {
    .symptoms-chart .symptom-row .symptom-item {
        border-bottom: 0;
        border-right: 5px solid #FFF;
        float: left;
        min-height: 180px;
        width: 25%;
    }
}
.symptoms-chart .symptom-row .symptom-item--image {
    background-color: $midnightBlue; 
    background-image: none;
    border-top: 5px solid #FFF;
    padding: 10px 0 0 0;
}
.symptoms-chart .symptom-row .symptom-item--image h2 {
    //font-size: 1.6rem !important;
    @include font-size(16px);
    margin-bottom: 10px !important;
    color: white;
}
.symptoms-chart .symptom-row .symptom-item--image img {
    width: 100%;
}
.symptoms-chart .symptom-row .symptom-item h2 {
    //font-size: 1.8rem;
    @include font-size(18px);
    font-weight: bold;
    margin: 0;
}
.symptoms-chart .symptom-row .symptom-item h4 {
    //font-size: 1.4rem;
    @include font-size(14px);
    font-weight: normal;
    margin: 0;
}
.symptoms-chart .symptom-row .symptom-item span {
    display: block;
    //font-size: 1.4rem;
    @include font-size(14px);
    font-weight: normal;
    margin-top: 10px;
}
