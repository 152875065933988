// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

/**
 * Set up a decent box model on the root element
 */
html {
  box-sizing: border-box;
}
body {
	margin:0;
	padding:0;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  line-height: 1.2;
}

body,
html {
   height: 100%;
}

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*, *::before, *::after {
  box-sizing: inherit;
} 



//Legacy brought in by hand
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 5px;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
}
h1, h2, h3, h4, h5, h6 {
  color: #002f65;
}
h2 {
  @include font-size(24px);
  font-weight: 500;
  sup {
    font-size: 50%;
    //top: -.6em;;
  }
}
h1 {
  @include font-size(26px);
  sup {
    font-size: 50%;
    top: -.6em;;
  }
}
h3 {
  @include font-size(22px);
}
h4 {
  @include font-size(20px);
}
.secondary-heading {
  color: #002f65;
  font-weight: bold;
  font-size: 16px;
}

.color-red {
  color: #c4121d;
}
@media (min-width: 1200px) {
  .col-xl-10 {
    width: 83.3333333333%;
  }
}

.footnote {
  sup {
    font-size: 50%;
    margin-left: 1px;
  }
}

a:hover {
  svg.color-red {
    fill: #002f65;
  }
}