

.alert__content {
    font-family: 'Helvetica';
    font-size: 12px;
    color: #000000;
    border: solid 1px #000;
    padding: 15px;
    background-color: #ffffff;
    font-weight: 500;    
}

.alert {
    position: relative;
    padding: 30px 10px 10px 10px !important;
    margin: 0;

    a {
        text-decoration: none !important;
    }
}

.alert__close {
    position: absolute;
    top: 0;
    right: 8px;
    font-family: serif;
    font-size: 32px;
    line-height: 1;
    color: #000000;
    &:hover {
        cursor: pointer;
    }
}

@media (min-width: 768px) {
    .alert {
        padding: 30px 40px 15px 40px !important;
        margin-bottom: 0px !important;
    }
    .alert__content {
        font-size: 18px;
        padding: 15px 40px;
        text-align: center;
    }
    
    .alert__close {
        top: 8px;
        right: 10px;

    }    
}



















/*For IE Browser styles */
@media screen and (-ms-high-contrast:active),
screen and (-ms-high-contrast:none) {
    .alert__close {
        right: 14px !important;
    }

}