

// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------
.headerMain {
    position: relative;
    z-index: 10;
    //border-bottom: 1px solid $colorGrayLighter;
    @include media-breakpoint-up(lg) {
       transition: all .5s;
       .header-sticky & {
          position: fixed;
          z-index: 300;
          top: 0;
          right: 0;
          left: 0;
          animation: fadein .5s;
 
          .headerMain-util {
             display: none;
          }
       }
    }
 }
 
 .headerMain-util {
   background: $colorGray2;
   
   .headerMain-navUtilLink {
     color: $white;
     &:hover {
       color: $white;
       text-decoration: underline;
     }
   }
 }
 
 .headerMain-utilInner {
    display: flex;
    flex-wrap: wrap;
    max-width: $maxWidth;
    margin-right: auto;
    margin-left: auto;
    position: relative;
    justify-content: space-between;
    flex-direction: column-reverse;
    
    @include media-breakpoint-up(lg) {
      flex-direction: row;
    }
    @include media-breakpoint-up(lg) {
       padding-right: 15px;
       padding-left: 15px;
    }
 }
 
 .headerMain-navUtil {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding-left: 0;
    margin-bottom: 0;
    width: 100%;
    justify-content: space-evenly;
    text-align: center;
    @include media-breakpoint-up(md) {
      width: auto;
      li:last-child {
        a {
          border-right: none;
        }
      }
    }
    @include media-breakpoint-up(lg) {
 
      flex-wrap: nowrap;
       margin-bottom: 0;
       padding: 0;
       list-style: none;
       font-size: $headerMain-navUtil-size;
    }
 }
 
 .headerMain-navUtilItem {
   list-style: none;
   flex:1;
   border-left: 1px solid $white;
   &:first-child {
     border-left: 0;
     .headerMain-navUtilLink {
       border-left: none;

     }
   }
    @include media-breakpoint-up(lg) {
      width: auto;
      flex-basis: auto;
      border: none;
      display: block;
    }
 }
 
 .headerMain-navUtilLink {
    display: block;
    cursor: pointer;
    margin: 8px 0 8px;
    padding: 0 4px 0 4px;
    text-decoration: none;
    color: $colorGray;
    font-size: 0.825rem;
    line-height: 1rem;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    @include media-breakpoint-up(md) {
      font-size: 1rem;
      padding: 0 10px 0 10px;
    }
    &:hover, &:active  {
       text-decoration: none;
       color: $headerMain-navUtilLink-active-color;
    }
    @include media-breakpoint-up(md) {
      align-items: center;
      font-size: 0.875rem;
    }
 
    @include media-breakpoint-up(lg) {
       flex: 0 1 auto;
    }
 }
 
 
 .headerMain-main {
      background: $headerMain-main-background;
      @include media-breakpoint-up(lg) {
        //min-height: 8.413125rem;
      }
      
 }
 
 .headerMain-mainInner {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    max-width: $maxWidth;
    margin-right: auto;
    margin-left: auto;
    padding-right: 15px;
    padding-left: 15px;
    position: relative;
    .header-sticky & {
       align-items: center;
    }
    @include media-breakpoint-up(lg) {
       flex-wrap: nowrap;
       align-items: center;
    }
 }
 
 .headerMain-logo {
    padding-top: $spacer*.7;
    padding-bottom: $spacer*.7;
    width: 197px;
    @include media-breakpoint-up(lg) {
      padding-top: $spacer*1;
      padding-bottom: $spacer*1;
      margin-right: 2rem;
    }
 }
 .header-sticky {
   .headerMain-logo {
     padding-top: 0.5rem;
     padding-bottom: 0.5rem;
   }
 }
 
 .headerMain-logoLink {
   display: block;
 
   @include media-breakpoint-up(xl){
     position: relative;
     z-index: 100;
     left:-31px;
   }
 }
 
 .headerMain-logoImg {
    display: inline-block;
    max-width: 100%;
    height: auto;
    @include media-breakpoint-up(lg) {
      width: 194px;
    }
    .header-sticky & {
       width: 144px;
    }
 }
 
 .headerMain-note {
   position: absolute;
   bottom: -25px;
   text-align: center;
   //width: 100%;
   left: 50%;
   margin-left: -93px;
   color: white;
   font-size: 0.625rem;
   @include media-breakpoint-up(md) {
     text-align: left;
     left: 0;
     margin-left: 15px;
   }
 }
 
 
 .headerMain-navMain {
    overflow: hidden;
    order: 3;
    min-width: calc(100% + 30px);
    max-height: 0;
    margin-right: -15px;
    margin-bottom: 0;
    margin-left: -15px;
    padding: 0;
    list-style: none;
    align-items: flex-start;
    background-color: #e8e8e8;

    &.is-open {
       max-height: 880px;
             transition: all .5s ease-in;
             // overflow: visible;
       &::before {
         //content: "";
         width: 100%;
         height: 4px;
         display: block;
         @include linear-gradient(90deg, $colorBrandRed, $midnightBlue);
         @include media-breakpoint-up(lg) {
           height: 8px;
         }
       }
    }
    @include media-breakpoint-up(lg) {
       position: relative;
       display: flex;
       overflow: visible;
       order: 0;
       min-width: 0;
       max-height: none;
       //margin-right: 0;
       //margin-bottom: 0;
       //margin-left: 0;
       background: none;
       justify-content: flex-end;
       .header-sticky & {
          align-self: stretch;
          max-height: 74px;
          align-self: center;
       }
    }
    .headerMain-navMainItem:last-child {
      .headerMain-navMainLink {
        //border-bottom: none;
      }
    }
    .headerMain-navUtilMobile {
      border-top: none;
      background-color: $colorGray;
      .headerMain-navUtilMobileItem {
        border: none;
      }
      .headerMain-navUtilMobileItem:first-child {
        min-width: 100%;
        width: 100%;
        padding-bottom: 0.75rem;
        border-bottom: 1px solid white;
        margin-bottom: 0.75rem;
      }
      .headerMain-navUtilMobileItem:last-child {
        border-left: 1px solid $colorBrandRed;
      }
    }
 }
 .headerMain-navMainItem {
    @include media-breakpoint-up(lg) {
      padding: 1rem;
       &:hover, &:active {
          .headerMain-subContent .headerMain-subContentInner {
             z-index: 2;
             max-height: 300px;
          }
          .headerMain-subContent {
            &:after {
              position: absolute;
              z-index: -1;
              bottom: -2px;
              left: 50%;
              width: 200vw;
              height: 2px;
              content: '';
              transform: translateX(-50%);
              background: orange;
            }
          }
       }
       &:last-child {
         .headerMain-navMainLink {
           padding-right: 0;
           &:after {
             display: none;
           }
         }
       }
       &:hover {
        .headerMain-navMainLink {
          border-bottom: 3px solid $colorBrandRed;
        }
       }
    }
 }
 .headerMain-navUtilItem--patient {
   width: 100%;

 }
 

 
 .headerMain-navMainLink {
    display: flex;
    align-items: center;
    padding: $spacer*1;
    text-decoration: none;
    color: #002f65;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1rem;
    line-height: 1;
    border-bottom: 1px solid white;
    @include media-breakpoint-up(lg) {
      //padding: 1rem $spacer*1;
      padding: 3px 5px;
      text-align: center;
      display: block;
      justify-content: flex-start;
      position: relative;
      border-bottom: 3px solid white;
      &:after {
        //content: '';
        width: 2px;
        height: 20px;
        background: $midnightBlue;
        display: block;
        position: absolute;
        top: 50%;
        margin-top: -10px;
        right: 0px;
      }
    }
    @include media-breakpoint-up(xl) {
      text-align: center;
      //padding: 1.5rem $spacer*1;
    }
    &:active, &.active {
       text-decoration: none;
       //background-color: #808892 !important;
       color: $colorBrandRed;
       //background: palette(colorBrandRed,base);
    }
    &:hover {
      background-color: #808892 !important;
      color: $white;
    }

    .is-current & {
       color: $headerMain-navMainLink-mobile-active-color;
       background: $headerMain-navMainLink-mobile-active-background;
    }
    @include media-breakpoint-up(lg) {
       font-size: 0.875rem;
       border-top-width: 0;
       color: $headerMain-navMainLink-color;
       &:visited {
         //color: $colorGray;
       }
       &.hasChildren:after {
          display: none;
       }
       .is-current & {
          color: $headerMain-navMainLink-active-color;
          background: transparent;
       }
       .header-sticky & {
          height: 100%;
          //padding: $spacer*1 $spacer*1.5;
          text-align: center;
       }
       &:hover, &:active, &.active {
          color: $headerMain-navMainLink-active-color;
          background: none !important;
          border-bottom: 3px solid $headerMain-navMainLink-active-color;
       }
    }
 }
 .headerMain-navMainItem {
   &:last-child {
     .headerMain-navMainLink {
       //border-bottom: none;
     }
   }
   &.is-open {
     .headerMain-navMainLink {
       &.hasChildren {
         border-color: transparent;
       }
     }
   }
 }
 
 
 .headerMain-navMainToggle {
    position: relative;
    display: inline-block;
    width: 45px;
    height: 24px;
    margin-left: 5px;
    
    @include media-breakpoint-up(lg) {
       display: none;
    }
 }
 
 .headerMain-navMainToggleLine {
    position: absolute;
    display: block;
    width: 48px;
    height: 5px;
    -o-transition: all .4s;
    -webkit-transition: all .4s;
    -moz-transition: all .4s;
    transition: all .4s;
    border-radius: 0px;
    right: 0;
    background: $colorGray2;
    border-radius: 4px;

    &:first-child {
       top: 0;
       width: 48px;
       right: 0;
       .is-active & {
          transform: translateY(12px) translateX(0) rotate(45deg);
          //background: map-get(map-get($palettes, 'colorBrandRed'), 'base');
       }
    }
    &:nth-child(2) {
       top: 50%;
       .is-active & {
          opacity: 0;
       }
    }
    &:last-child {
       top: 100%;
       width: 48px;
       right: 0;
       .is-active & {
          transform: translateY(-12px) translateX(0) rotate(-45deg);
          //background: map-get(map-get($palettes, 'colorBrandRed'), 'base');
       }
    }
 }
 
 @include media-breakpoint-down(lg) {
  .headerMain.is-open {
    .headerMain-main {
      background: white;
      
      .headerMain-navMainLink {
        //color: #002f65;
        &:hover {
          color: white;
          text-decoration: none;
        }
      }
    }
    .headerMain-util { 
      .headerMain-navUtilLink {
        color: $colorGray;
      }
    }
  }
}


.headerMain-navSub {
  
  border-bottom: 1px solid $white;
  .headerMain-navSubLink {
    &:active, &.active {
      text-decoration: none;
      //background-color: #808892 !important;
      color: $colorBrandRed;
      //background: palette(colorBrandRed,base);
   }
   &:hover {
     background-color: #808892 !important;
     color: $white;
     @include media-breakpoint-up(lg) {
       background: none !important;
     }
   }
   &:first-child {
    &:active, &.active {
      //text-decoration: none;
      //background-color: #808892 !important;
      color: $midnightBlue;
      //background: palette(colorBrandRed,base);
    }
   }
  }
  @include media-breakpoint-up(lg) {
    display: none;
    position: absolute;
    background-color: #EBEBEB;
    border: 1px solid $midnightBlue;
    padding-left: 15px;
    padding-right: 15px;
    .headerMain-navSubLink {
      color: $midnightBlue;
      &:hover {
        color: $colorBrandRed;
        background: none;
      }
    }
  }
  .headerMain-navSubLink {
    padding-top: 10px;
    padding-bottom: 10px;
    color: $midnightBlue;
    display: block;
    @include media-breakpoint-down(lg) {
      padding-left: 30px;
      padding-right: 15px;
      &:hover {

      }
    }
    
  }
  
}

.headerMain-navMainItem {
  &:hover {
    .headerMain-navSub {
      @include media-breakpoint-up(lg) {
        display: block;
      }
    }

  }
}