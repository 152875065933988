.launchTooltip {
    cursor: pointer;
    //border-bottom: 1px solid;
  }
  
  
  //tippy tooltips style overwrites
  .tippy-tooltip {
    background: white;
    color: $colorGray2;
    border: 1px solid $colorBrandBlue;
    border-radius: 4px;
    text-align: left;
    padding: 1.5rem;
    font-size: 0.875rem;
    .refItem {
      display: block;
      overflow-wrap: break-word;
      word-wrap: break-word;
      -ms-word-break: break-all;
      /* Instead use this non-standard one: */
      word-break: break-word;
      padding-left: 1rem;
      position: relative;
    }
    .number {
      position: absolute;
      left: 0;
      top: 0;
    }
    .refSpace {
      display: none;
    }
  }
  
  .tippy-tooltip .tippy-arrow { 
    transform: scale(1.5);
}
  
  /* Right placement tooltip arrow (arrow will be on left side of tooltip) */
  .tippy-popper[x-placement^=right] .tippy-tooltip .tippy-arrow {
      border-right: 7px solid $colorBrandBlue; /* your color here */
  }
  
  /* Left placement tooltip arrow (arrow will be on right side of tooltip) */
  .tippy-popper[x-placement^=left] .tippy-tooltip .tippy-arrow {
      border-left: 7px solid $colorBrandBlue; /* your color here */
  }
  
  /* Top placement tooltip arrow (arrow will be on bottom side of tooltip) */
  .tippy-popper[x-placement^=top] .tippy-tooltip .tippy-arrow {
      border-top: 7px solid $colorBrandBlue; /* your color here */
  }
  
  /* Bottom placement tooltip arrow (arrow will be on top side of tooltip) */
  .tippy-popper[x-placement^=bottom] .tippy-tooltip .tippy-arrow {
      border-bottom: 7px solid $colorBrandBlue; /* your color here */
  }


  .tip {
    @include media-breakpoint-up(lg) {
        text-decoration: underline;
    }
  }