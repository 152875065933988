// -----------------------------------------------------------------------------
// This file contains all styles related to global notify message.
// -----------------------------------------------------------------------------
.showToggle {
	color: $colorBrandRed !important;
	text-transform: uppercase;
	font-weight: bold;
	cursor: pointer;
	&:hover, &:active  {
		color: $midnightBlue;
	}
	.fa {
		font-weight: bold;
	}
}
.ellipse {
	font-weight: normal;
	color: $colorGray;
}
.notifyMain {
	position:relative;
	color:$notifyMain-color;
	transition: all .5s ease-in-out;
	transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
	overflow:hidden;
	&.is-closed {
		max-height: 0;
	}
}
.notifyMain-inner {
	max-width:$maxWidth;
	margin-right:auto;
	margin-left:auto;
	display:flex;
	padding: 0.657rem 44px 0.657rem 14px;
	position: relative;
}
.notifyMain-copy {
	@extend %noPhatBottom;
	transition: max-height .25s ease-in-out;
	line-height: 1.3;
	font-size: 0.875rem;
	font-family: $headings-font-family;
	p {
		margin-bottom: 0.75rem;
		line-height: 1.3;
		font-size: 0.875rem;
	}
	ul {
		line-height: 1.3;
		font-size: 0.875rem;
    list-style: none;
    color: $midnightBlue;
		padding-left: 1.5rem;
		display: inline;
    li:before {
      content: "●";
      color: $midnightBlue;
      display: inline-block;
      width: 1rem;
      margin-left: -1rem;
      margin-right: 0;
      font-size: 0.75rem;
      position: relative;
      top: -2px;
    }
	}
	li:first-child {
		display:inline;
	}
	&.expanded {
		li {
			//display: list-item;
		}
	}
}
.notifyMain-heading {
	color:$midnightBlue;
	font-size:$notifyMain-heading-size;
	font-family: $headings-font-family;
	margin-bottom:0.25rem;
	text-transform:uppercase;
	font-weight: bold;
	display: block;
}
.notifyMain-content {
	//@extend %noPhatBottom;
	//flex: 1 1 auto;
}
.notifyMain-close {
	color: $midnightBlue;
	font-size:$notifyMain-close-size;
	margin-left:0rem;
	cursor:pointer;
	position: absolute;
	right: 3px;
	top:3px;
	font: normal normal normal 14px/1 FontAwesome;
	&:before {
		content: "\f00d";
		vertical-align: middle;
		font-size: 1.5rem;
		line-height: 1.5rem;
		font-weight: 200;
	}
	&:hover, &:active {
		color: $colorBrandRed;
	}
}

.hide-sm {
	@include media-breakpoint-down(md) {
		display: none;
	}
}
.hide-lg {
	display: none;
}
.is-expanded {
	p.hide-sm, li.hide-sm {
		display:block;
	}
	span.hide-sm, span.hide-lg {
		display: inline;
	}
	.showToggle {
		display: none;
	}
	ul {
		display: block;
	}
}

.notifyMain-copy p:first-child {
    display: inline;
}




/**
 * partials/head-indication
 * ---
 *
 */

 #indication {
    position: relative;
    padding: 1rem 0;
    background: #d3d3d3;
}
#indication .heading {
    color: $midnightBlue;
    font-weight: bold;
}
#indication .desc {
    margin: 0;
    display: block;
    /* Fallback for non-webkit */

    display: -webkit-box;
    width: 100%;
    overflow: hidden;
    line-height: 1.8rem;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    height: 100%;
}
#indication .desc a {
    color: #c4121d;
    transition: all 0.5s ease-in-out;
}
#indication .desc a:hover {
    color: #002f65;
}
#indication .indH {
    -webkit-line-clamp: inherit;
    height: 35px;
}
#indication .btn-close {
    position: absolute;
    top: 0;
    right: 1.5rem;
    width: 1.7rem;
    height: 1.7rem;
    cursor: pointer;
}
#indication .btn-close img,
#indication .btn-close svg {
    width: 100%;
    height: auto;
    fill: #002f65;
    transition: all 0.5s ease-in-out;
}
#indication .btn-close:hover img,
#indication .btn-close:hover svg {
    fill: #c4121d;
}
#indication .read-more {
    right: 20px;
    bottom: -0.1rem;
    padding: 0 1.5rem 0 0;
    background: #d3d3d3;
    white-space: nowrap;
}
#indication .read-more a {
    color: #c4121d;
    text-transform: uppercase;
    transition: all 0.5s ease-in-out;
}
#indication .read-more a img,
#indication .read-more a svg {
    vertical-align: middle;
    position: relative;
    top: -2px;
    fill: #c4121d;
    transition: all 0.5s ease-in-out;
}
#indication .read-more a:hover {
    color: #002f65;
}
#indication .read-more a:hover img,
#indication .read-more a:hover svg {
    fill: #002f65;
}
#indication.hide {
    display: none;
}
#indication .hdn-pt {
    display: none;
}
@media (min-width: 768px) {
    #indication .btn-close {
        top: -0.8rem;
    }
}
@media (max-width: 1199px) {
    #indication .read-more {
        position: absolute;
    }
}