// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site/application.
// -----------------------------------------------------------------------------

 .footerMain-topLink {
   margin-top: 1rem;
   margin-left: auto;
   margin-right: auto;
   display: inline-block;
   background: $colorBrandRed;
   font-weight: normal;
   text-transform: uppercase;
   color: $white;
   padding: 10px 15px;
   text-align: center;
   text-transform: uppercase;
   display: inline-flex;
   align-items: center;
   &:after {
      content: "\f106";
      display: inline-block;
      font-family: FontAwesome;
      font-style: normal;
      font-weight: normal;
      font-size: 1.875rem;
      line-height: 0;
      position: relative;
      margin-left: 5px;
      position: relative;
      top: -3px;
    }
    &:hover, &:active  {
      text-decoration: none;
      color: $white;
    }
    &:focus {
      text-decoration: none;
      color: $white;
    }
 }

 .footerMain {
  position: relative;
  padding-bottom: $spacer*2;
  font-size: $footerMain-size;
  color: $footerMain-color;
  background: $footerMain-background;
  font-family: $headings-font-family;
  border-top: $colorBrandRed solid 4px;
  @include media-breakpoint-up(lg) {
    font-weight: 700;
  }
}

 .footerMain-main {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: $spacer*1.5;
    @include media-breakpoint-up(md) {
       padding-top: $spacer*3;
       flex-direction: row;
       justify-content: space-between;
       align-items: flex-end;
    }
    @include media-breakpoint-up(lg) {
    }
 }
 .footerMain-inner {
    max-width: $maxWidth;
    margin-right: auto;
    margin-left: auto;
    padding-right: 15px;
    padding-left: 15px;
    @include media-breakpoint-up(md) {
      padding-right: 30px;
      padding-left: 30px;
    }
 }
 .footerMain-logo-primary {
  text-align: right;
   @include media-breakpoint-up(md) {

   }
 }

 
 .footerMain-nav {
    margin-bottom: 0;
    padding: 0;
    list-style: none;
    font-size: $footerMain-nav-size;
    @include media-breakpoint-up(md) {

      li {
        display: inline-block;
        &:first-child {
          border-left: none;
        }
        @include media-breakpoint-up(lg) {

        }
      }
    }
    @include media-breakpoint-up(lg) {

    }
 }
 .footerMain-navItem {
   margin-bottom: 1rem;
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
    }
 }
 .footerMain-navLink {
    display: block;
    text-decoration: none;
    color: $midnightBlue;
    font-weight: bold;
    @include media-breakpoint-up(md) {
      padding-right: 0.5rem;
    }
    @include media-breakpoint-up(lg) {
       padding-right: 1.5rem;
       white-space: nowrap;
       padding-top: 0;
    }
    &:hover {
     color: $colorBrandRed;
    }
 }
 .footerMain-topLinkIcon {
    margin-left: $spacer/2;
    color: $colorBrandRed;
    font-size: 1.5rem;
 }
 .footerMain-collateral {
    font-weight: normal;
    border-top: 2px solid $white;
    text-align: left;
    margin-top: 1rem;
    padding-top: 1rem;
    p {
        margin-bottom: 0;
        //@include font-size(12px);
        line-height: 18px;
    }
 }
 