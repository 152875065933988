// -----------------------------------------------------------------------------
// This file contains all styles related to global notify message.
// -----------------------------------------------------------------------------
.notifyTray {
   transition: height .3s ease-in-out;
   font-size: 1rem;
   border-top: 1px solid $colorBrandRed;
   background: white;
   margin-top: 20px;
   //margin-bottom: 20px;
   .notifyTray-content {
     padding-right: 2rem;
     position: relative;
     padding-top: 5px;
     @include media-breakpoint-up(lg) {
     }
   }
   .notifyTray-inner  {
     padding-top: $spacer*1;
   }
   a {
      text-decoration: none;
      &:hover, &:active {
        color: $colorBrandRed;
      }
   }
   h2 {
     color: $black;
     text-transform: uppercase;
     font-size: 1rem;
     font-weight: 600;;
   }
   h3 {
     @include font-size(18px);
     font-weight: bold;
   }
   h4 {
     //display: inline;
     font-weight: bold;
     margin-bottom: .25rem;
   }
   h4 + h4 {
     margin-top: .75rem;
   }
   ul + h4 {
     margin-top: 1.25rem;
   }
   h5 {
     font-weight: bold;
     @include font-size(18px);
   }
   p {
     margin-bottom: 1.25rem;
     line-height: normal;
     @include media-breakpoint-up(md) {

     }
   }
   ul, ol {
     padding-left: 16px;
     font-size: 1rem;
     line-height: normal;
     @include media-breakpoint-up(md) {
       font-size: 1rem;
     }
     li {
       margin-bottom: 4px;
     }
   }
   ul {
     list-style: none;
     li:before {
       content: "\2022";
       color: $colorBrandRed;
       display: inline-block;
       width: 1rem;
       margin-left: -1rem;
       margin-right: 0;
       //font-size: 12px;
       vertical-align: text-top;
     }
   }
   .indication-heading {
    color: $midnightBlue;
    margin-top: 20px;
    text-transform: none;
    @include font-size(18px);
   }

   &.is-sticky {
      position: fixed;
      z-index: 300;
      right: 0;
      bottom: 0;
      left: 0;
      //overflow: hidden;
      height: 95px;
      padding: 0;
      line-height: 1.4;
      background: $colorWhite;
      @include media-breakpoint-up(lg) {
        height: 110px;
      }
      .notifyTray-inner {
         //overflow: hidden;
         max-width: $maxWidth;
         max-height: 100%;
         margin-right: auto;
         margin-left: auto;
         padding: $spacer*0.5 0 0 0;
      }
      &:not(.full) {
        h2, h3 {
          display: inline;
          @include font-size(14px);
        }
        .notifyTray-content {
          padding-top: 0;
        }
        .notifyTray-content {
          padding-right: 1.5rem;
          line-height: 1.125rem;
          @include media-breakpoint-up(md) {
            padding-right: 2rem;
          }
        }
        .notifyTray-content ul {
          font-size: 0.875rem;
          display: inline;
          padding-left: 0px;
          @include media-breakpoint-up(md) {

          }
          li {
            display: inline;
            padding-left: 20px;
            @include media-breakpoint-up(md) {

            }
          }
        }
      }
   }
   &.full {
      overflow: scroll;
      -webkit-overflow-scrolling: touch;
      .notifyTray-inner {
         overflow: visible;
         max-height: none;
      }
      h3 {
        font-size: 1.125rem;
      }
   }
}

.notifyTray-toggle {
   display: none;
   .is-sticky & {
      display: block;
      text-align: right;
      text-transform: uppercase;
      position: absolute;
      right: 0px;
      top: 5px;
      @include media-breakpoint-up(md) {
        right: -12px;
        top:5px;
      }
   }
}
.notifyTray-toggleLink {
   font-size: 2rem;
   font-family: $headings-font-family;
   color: $midnightBlue;
   font-weight: 600;
   line-height: 0;
   &:hover, &:active  {
      
      color: $colorBrandRed;
      @include media-breakpoint-down(sm) {
        &:before {
          color: $colorBrandRed;
        }
      }
   }
   &:before {
      color: $notifyTray-toggle-color;
   }
   .is-sticky & {
      padding-left: $spacer;
      cursor: pointer;
   }
   .is-sticky.full & {

   }
   span {
     display: inline;
     @include media-breakpoint-up(lg) {
       display: inline;
     }
   }
}





//Iphone 5
@media only screen and (max-width:22.9rem) {
  .notifyTray.is-sticky .notifyTray-inner, .paidISI.is-sticky .notifyTray-inner {
    padding-top: 0.35rem;
  }
  .container {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .notifyTray.is-sticky:not(.full) .notifyTray-content, .paidISI.is-sticky:not(.full) .notifyTray-content {
    padding-right: 0;
  }

}


.references {
  line-height: normal;
}


