
body {
    margin: 0;
    padding: 0;
    width: 100%;
}
body.disable-scroll {
    overflow: hidden;
}
a {
    color: #c4121d;
}
a:hover,
a:active,
a:focus {
    color: #c4121d;
    text-decoration: none;
}
span.t-phone {
    color: #c4121d;
}
@media screen and (max-width: 767px) {
    .hidden-mobile {
        display: none;
    }
}
@media screen and (min-width: 768px) {
    .hidden-desktop {
        display: none;
    }
}
@media screen and (max-width: 992px) {
    .container {
        max-width: 100% !important;
    }
}
.btn-link-bg {
    background: #c4121d !important;
    border: 2px solid #c4121d;
    color: #FFF !important;
    transition: all .4s ease;
}
.btn-link-bg:hover,
.btn-link-bg:focus {
    background: #002f65 !important;
    color: #FFF !important;
}
@media (min-width: 992px) {
    .btn-link-bg {
        border-radius: 6px;
        display: inline-block;
        padding: 3px 12px;
    }
}
.accent-bg {
    display: none;
}
@media (min-width: 992px) {
    .accent-bg {
        background: #e5e5e5;
        display: block;
        height: 100%;
        margin: 0;
        padding: 0;
        position: absolute;
        top: 0;
        z-index: -1;
    }
    .accent-bg--right {
        right: 0;
    }
    .accent-bg--right:before {
        background: #e5e5e5;
        border-style: solid;
        border-radius: 50%;
        border-bottom: 0;
        border-top: 0;
        border-left-color: #e5e5e5;
        border-left-width: 100px;
        border-right: 0;
        content: " ";
        display: block;
        height: 110%;
        left: -30px;
        position: absolute;
        top: -5%;
        width: 50px;
    }
    .accent-bg--right--reverse:before {
        background: #F9F3E9;
        border-style: solid;
        border-radius: 50%;
        border-bottom: 0;
        border-top: 0;
        border-right-width: 75px;
        border-left: 0;
        border-right-color: #e5e5e5;
        content: " ";
        display: block;
        height: 110%;
        left: -24%;
        position: absolute;
        top: -5%;
        width: 240px;
    }
    .accent-bg--alt {
        background: #F0E3CF;
        z-index: 1;
    }
    .accent-bg--alt:before {
        border-right-color: #F0E3CF;
    }
}
@media screen and (min-width: 1600px) {
    .accent-bg {
        width: 38%;
    }
}
.chart-heading {
    font-weight: bold;
    margin-bottom: 0 !important;
}
.no-word-wrap {
    white-space: nowrap;
}
/* Custom Styles */
/**
 * modules/variables
 * ---
 * Declare variables here
 */
/**
 * modules/mixins
 * ---
 */
/**
 * modules/fonts
 * ---
 * Declare fonts here
 */
/*
@font-face {
    font-family: "Font-Name";
    src: url("fonts/font-name.eot");
    src: url("fonts/font-name.eot?#iefix") format("embedded-opentype"),
        url("fonts/font-name.woff") format("woff"),
        url("fonts/font-name.ttf") format("truetype"),
        url("fonts/font-name.svg#font-name") format("svg");
    font-weight: normal;
    font-style: normal;
}
*/
/**
 * partials/base
 * ---
 * Declare Base/Global/Module Over-Rides styles here
 */

html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
body {
    overflow-x: hidden;
}
body.noscroll {
    overflow: hidden;
}
a:focus,
a:active {
    text-decoration: none;
}
sub,
sup {
    font-size: 70%;
}
.headline sup {
    font-size: 50%;
    top: -0.9em;
}
.subhead sup {
    font-size: 55%;
    top: -0.65em;
}
h4 sup {
    font-size: 55%;
    top: -0.6em;
}
h2 sup {
		top: -1em;
		font-size: 40%;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    color: #002f65;
}
p {
    margin-bottom: 1rem;
}
::-moz-selection {
    background: #ffcf01;
    color: #003c64;
}
::selection {
    background: #ffcf01;
    color: #003c64;
}
::-moz-selection {
    background: #ffcf01;
    color: #003c64;
}
.text--center {
    text-align: center;
}
.color--default {
    color: #6e6e6e;
}
.color--black {
    color: #000 !important;
}
.color--blue {
    color: #002f65;
}
.color--red {
    color: #c4121d;
}
.color--brand {
    color: #002f65 !important;
}
.text--lg {
    font-size: 1.8rem;
}
.text--right {
    text-align: right;
}
.form-control {
    font-size: 1.4rem;
}
.form-control.fc--white {
    border: 1px solid #fff;
}
.arrow--right {
    position: relative;
    display: inline-block;
    transition: all 0.5s ease-in-out;
    padding-right: 1.5rem;
}
.arrow--right:after {
    display: block;
    content: "";
    position: absolute;
    top: 0.3rem;
    right: 0;
    width: 8px;
    height: 14px;
    background: url('data:image/svg+xml,%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%20viewBox%3D%220%200%207.7%2013.9%22%20enable-background%3D%22new%200%200%207.7%2013.9%22%20xml%3Aspace%3D%22preserve%22%3E%3Cpath%20fill%3D%22%23c4121d%22%20d%3D%22M0%2C0.8c0-0.2%2C0.1-0.4%2C0.2-0.5c0.3-0.3%2C0.8-0.3%2C1.1%2C0l6.2%2C6.2c0.3%2C0.3%2C0.3%2C0.8%2C0%2C1.1l-6.2%2C6.2%0A%09C1%2C14%2C0.5%2C14%2C0.2%2C13.7s-0.3-0.8%2C0-1.1L5.9%2C7L0.2%2C1.3C0.1%2C1.1%2C0%2C0.9%2C0%2C0.8%22%20%2F%3E%3C%2Fsvg%3E') no-repeat;
    background-size: cover;
    transition: all 0.5s ease-in-out;
}
.arrow--right:hover:after {
    background-image: url('data:image/svg+xml,%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%20viewBox%3D%220%200%207.7%2013.9%22%20enable-background%3D%22new%200%200%207.7%2013.9%22%20xml%3Aspace%3D%22preserve%22%3E%3Cpath%20fill%3D%22%23002F65%22%20d%3D%22M0%2C0.8c0-0.2%2C0.1-0.4%2C0.2-0.5c0.3-0.3%2C0.8-0.3%2C1.1%2C0l6.2%2C6.2c0.3%2C0.3%2C0.3%2C0.8%2C0%2C1.1l-6.2%2C6.2%0A%09C1%2C14%2C0.5%2C14%2C0.2%2C13.7s-0.3-0.8%2C0-1.1L5.9%2C7L0.2%2C1.3C0.1%2C1.1%2C0%2C0.9%2C0%2C0.8%22%20%2F%3E%3C%2Fsvg%3E');
}
.negative--indent {
    text-indent: -1rem;
}
button:hover,
button:focus,
button.focus,
button:active:focus,
button:active.focus,
button.active:focus,
button.active.focus {
    outline: none;
}
.btn {
    white-space: normal;
    text-align: left;
    transition: all 0.5s ease-in-out;
}
.btn--arrow {
    position: relative;
    padding-right: 4.5rem;
}
.btn--arrow img,
.btn--arrow svg {
    position: absolute;
    right: 1.5rem;
    top: 1.4rem;
    width: 1.2rem;
    height: 22px;
    fill: #fff;
    transition: all 0.5s ease-in-out;
}
.btn-primary:hover {
    background: #c4121d;
    border-color: #c4121d;
}
.size--20 {
    font-size: 2.0rem !important;
}
.size--18 {
    font-size: 1.8rem;
}
.size--16 {
    font-size: 1.6rem;
}
.size--14 {
    font-size: 1.4rem;
}
.size--12 {
    font-size: 1.2rem;
}
.size--10 {
    font-size: 1rem;
}
.small--caps {
    font-variant: small-caps;
}
.img--scale {
    width: 100%;
    height: auto;
}
.img--max {
    max-width: 100%;
    width: auto;
    display: inherit;
}
.img--center {
    margin: 0 auto;
}
.img--inline {
    display: inline-block;
}
.modal-content {
    margin-top: 30px;
    border-radius: 0;
    background-color: #002f65;
}
.modal-close {
    position: absolute;
    right: 0;
    top: -30px;
    width: 30px;
    height: 30px;
    background: #002f65;
    cursor: pointer;
    z-index: 9;
}
.modal-close:after {
    display: block;
    content: '\00D7';
    position: absolute;
    top: 5px;
    left: 0;
    width: 100%;
    height: 100%;
    color: #c4121d;
    text-align: center;
    font-size: 50px;
    line-height: 0.4;
}
.modal-close:hover {
    background: #c4121d;
}
.modal-close:hover:after {
    color: #fff;
}
.allIE .video-js {
    width: 100% !important;
    height: auto !important;
}
/* Always show control bar */

.video-js .vjs-slider {
    background: none;
    -ms-transform: none !important;
    transform: none !important;
}
/* Override tech height:100% */

@media (min-width: 768px) {
    .text--center--md--up {
        text-align: center;
    }
    .modal-content {
        margin-top: 0;
    }
    .modal-close {
        right: -30px;
        top: 0;
        z-index: 1000;
    }
    .modal {
        text-align: center;
        padding: 0 !important;
    }
    .modal:before {
        content: '';
        display: inline-block;
        height: 100%;
        vertical-align: middle;
        margin-right: -4px;
        /* Adjusts for spacing */
    }
    .modal-dialog {
        display: inline-block;
        text-align: left;
        vertical-align: middle;
    }
}