.icon-title {
    display: flex;
    margin-bottom: 1rem;
}
.icon-title-text {

    h2 {
        font-weight: bold;
        margin-top: 5px;
        padding-right: 32px;
        @include media-breakpoint-down(sm) {
            font-size: 20px;
        }
        @include media-breakpoint-up(md) {
            margin-top: 10px;
        }
    }
    h5 {
        font-weight: bold;
        padding-bottom: 10px;
        border-bottom: 1px solid $midnightBlue;
        @include font-size(18px);
        line-height: 1.25;
    }
    .icon-title-special, h5 {
        &:Last-child {
            border-bottom: 1px solid $midnightBlue;
            margin-bottom:5px;
        }
    }
    
}

.icon-title-special {
    display: flex;
}
.icon-title-icon {
    margin-bottom: 10px;
    margin-right: 10px;
    width: auto;
    @include media-breakpoint-down(sm) {
        //width: 50px;
        height: 50px;
    }
}