.externalInterstitial {
  left: 50%;
  max-width: calc(100vw - 2rem);
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  @include media-breakpoint-up(lg) {
    max-width: 920px;
  }
  .externalInterstitial-inner {
    align-items: center;
    background: $white;
    position: relative;
    width: 100%;
    padding: 1rem;
  }
  .externalInterstitial-close:not(.externalInterstitial-close--text) {
    // position: absolute;
    // right: 1rem;
    // top: 1rem;
    // color: $white;
    // border-radius: 50%;
    // width: 40px;
    // height: 40px;
    // background-color: $colorBrandBlue;
    // &:after {
    //   display: block;
    //   content: '\00D7';
    //   position: absolute;
    //   top: 10px;
    //   left: 0;
    //   width: 100%;
    //   height: 100%;
    //   color: $white;
    //   text-align: center;
    //   font-size: 50px;
    //   line-height: 0.4;
    // }
    // &:hover {
    //   background-color: $colorBrandRed;
    // }
  }
  .externalInterstitial-logo {
    width: 140px;
    margin-bottom: 1rem;
  }
  .externalInterstitial-title {
    @include font-size(18px);
    text-align: center;
    border-bottom: 1px solid #ccc;
    display: block;
    width: 100%;
    padding-bottom: 1rem;
    @include media-breakpoint-up(lg) {
      @include font-size(20px);
    }
  }
  .externalInterstitial-disclaimer {
    @include font-size(16px);
    line-height: rem(22px);
    margin-bottom: rem(32px);
    text-align: center;
    @include media-breakpoint-up(lg) {
      @include font-size(18px);
      line-height: rem(24px);
    }
  }
  .externalInterstitial-continue {
    // margin-bottom: 1rem;
    // background: #c4121d;
    // color: $white;
    // font-weight: bold;
    // &:hover {
    //   background-color: #002f65;
    //   color: $white;
    // }
  }
  .externalInterstitial-close--text {
    // color: $colorBrandBlue;
    // @include font-size(16px);
    // font-weight: bold;
    // @include media-breakpoint-up(lg) {
    //   @include font-size(18px);
    //   line-height: rem(24px);
    // }
  }
}