
.modal2 {
    .modal-content {
      background-color: $white;
      border-radius: 10px;
      
    }
    .modal-dialog {
      width: 96%;
      margin-left: auto;
      margin-right: auto;
    }
    @media (min-width: 744px) {
      .modal-dialog {
        width: 88%;
        max-width: 1120px;
        margin: 30px;
      }
      .modal-body {
        padding-left: 50px;
        padding-right: 50px;
      }
    }
    .modal-close {
      left: auto;
      right: 5px;
      color: $white;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      top: 5px;
  
    }
    .modal-close:after {
      color: $white;
      margin-top: 5px;
    }
    
  }
