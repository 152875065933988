 
 .footnote {
	font-size: 0.75rem;
	margin-bottom: 3px;
}


.fake-bullet {
    padding-left: 21px;
    position: relative;
    margin-left: -19px;
    @include media-breakpoint-up(md) {
      padding-left: 0;
      margin-left: 0;
    }
    .bullet {
      position: absolute;
      left: 0;
      text-align: right;
      top: 4px;
      width: 21px;
      @include media-breakpoint-up(md) {
        left: -22px;
      }
    }
    span.bullet {
      top: 0;
    }
  }
  