



/**
 * partials/wysiwyg
 * ---
 *
 */

 .wysiwyg .main-block {
    padding: 40px;
}
@media screen and (max-width: 992px) {
    .wysiwyg .main-block {
        padding: 20px 0;
    }
}
.wysiwyg .main-block.mb--tan {
    //background: #F9F3E9;
    //tan became gray
    background: #ebebeb;
}
.wysiwyg .inner-block {
    padding: 15px;
}
.wysiwyg .inner-block.ib--tan {
    background: #ebebeb;
}
.wysiwyg .inner-block.soc-chart {
    margin-left: -25px;
    margin-top: .8rem;
}
@media (max-width: 767px) {
    .wysiwyg .inner-block.soc-chart {
        margin-left: 0rem;
    }
}
@media (min-width: 768px) {
    .wysiwyg .inner-block.soc-chart .circle-stat-wrapper {
        position: relative;
        top: 0;
    }
}
@media (min-width: 1200px) {
    .wysiwyg .inner-block.soc-chart .circle-stat-wrapper {
        top: 30px;
    }
}
.wysiwyg h1,
.wysiwyg h2,
.wysiwyg h3,
.wysiwyg h4,
.wysiwyg h5,
.wysiwyg h6 {
    margin-bottom: 20px;
}
.wysiwyg h1 span,
.wysiwyg h2 span,
.wysiwyg h3 span,
.wysiwyg h4 span,
.wysiwyg h5 span,
.wysiwyg h6 span {
    font-family: Arial, Helvetica, sans-serif;
}
.wysiwyg .headline {
    font-weight: bold;
    //font-size: 3.7rem;
    @include font-size(37px);
    line-height: normal;
    color: #002f65;
    margin-bottom: 20px;
}
.wysiwyg .subhead {
    //font-size: 2.4rem;
    @include font-size(24px);
    line-height: 1.875rem;
    margin-bottom: 20px;
    color: #c4121d;
}
.wysiwyg p a {
    color: #c4121d;
    transition: all 0.5s ease-in-out;
}
.wysiwyg p a:hover {
    color: #002f65;
}
.wysiwyg p.p--lg {
    //font-size: 1.8rem;
    @include font-size(18px);
}
.wysiwyg .notes {
    margin: 20px 0;
    //font-size: 1.4rem;
    @include font-size(14px);
}
.p--hanging {
    text-indent: -5px;
}
.wysiwyg .notes h1.p--hanging,
.wysiwyg .notes h2.p--hanging,
.wysiwyg .notes h3.p--hanging,
.wysiwyg .notes h4.p--hanging,
.wysiwyg .notes h5.p--hanging,
.wysiwyg .notes h6.p--hanging {
    text-indent: -3px;
}
.wysiwyg .notes p:not(:last-of-type) {
    margin-bottom: 10px;
}
.wysiwyg .notes p:last-of-type {
    margin-bottom: 0;
}
.wysiwyg .notes p.p--indent {
    padding-left: 0.3px;
    text-indent: -0.3px;
}
.wysiwyg .notes p.p--hanging {
    text-indent: -0.3px;
}
.wysiwyg .notes.notes--arrow {
    position: relative;
    //font-size: 1.6rem;
    @include font-size(16px);
    padding-left: 24px;
    max-height: 40px;
    overflow: hidden;
    transition: all 0.5s ease-in-out;
}
.wysiwyg .notes.notes--arrow:before {
    display: block;
    content: "";
    width: 0.7rem;
    height: 0.75rem;
    background: url(__("/img/svg/icon-triangle-right.svg")) no-repeat;
    background-size: cover;
    position: absolute;
    left: 0;
    top: 0;
    transition: all 0.25s ease-in-out;
    cursor: pointer;
}
.wysiwyg .notes.notes--arrow.na---open {
    max-height: 100px;
}
.wysiwyg .notes.notes--arrow.na---open:before {
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    left: 0.3px;
    max-height: 100px;
}
.wysiwyg.faq h2 {
    //font-size: 2.4rem;
    @include font-size(24px);
    color: #c4121d;
    line-height: 1.875rem;
    margin-bottom: 20px;
}
.list-dots {
    margin: 25px 0 25px 14px;
    padding: 0;
    list-style: none;
}
.list-dots h6 {
    margin: 0;
}
.list-dots > li:not(:last-child) {
    margin-bottom: 1rem;
}
.list-dots > li:before {
    content: '\2022';
    display: block;
    position: relative;
    max-width: 0px;
    max-height: 0px;
    left: -14px;
    top: -3px;
    color: #002f65;
    //font-size: 2rem;
    @include font-size(20px);
}
.list-dots.list-dots--red > li:before {
    color: $colorBrandRed;
}
.list-dots > li.first {
    margin-left: -14px;
}
.list-dots > li.first:before {
    content: "";
}
.list-dots > li.first h4 {
    margin-bottom: 0;
}
.list-dots > li > ul {
    margin: 0;
    padding: 0;
    list-style: none;
    margin-top: 10px;
    margin-left: 25px;
}
.list-dots > li > ul > li:not(:last-child) {
    margin-bottom: 10px;
}
.list-dots > li > ul > li:before {
    content: '\2022';
    display: block;
    position: relative;
    max-width: 0px;
    max-height: 0px;
    left: -14px;
    top: -3px;
    color: #002f65;
    //font-size: 2rem;
    @include font-size(20px);
}
.list-dots > li > ul > li > ul {
    margin: 0;
    padding: 0;
    list-style: none;
    margin-top: 10px;
    margin-left: 25px;
}
.list-dots > li > ul > li > ul > li:not(:last-child) {
    margin-bottom: 10px;
}
.list-dots > li > ul > li > ul > li:before {
    content: '\2022';
    display: block;
    position: relative;
    max-width: 0px;
    max-height: 0px;
    left: -14px;
    top: -3px;
    color: #002f65;
    //font-size: 2rem;
    @include font-size(20px);
}
.list-squares {
    margin: 25px 0 25px 14px;
    padding: 0;
    list-style: none;
}
.list-squares h6 {
    margin: 0;
}
.list-squares > li:not(:last-child) {
    margin-bottom: 10px;
}
.list-squares > li:before {
    content: "\2022";
    display: block;
    position: relative;
    max-width: 0px;
    max-height: 0px;
    left: -14px;
    color: #c4121d;
    //font-size: 1.2rem;
    @include font-size(14px);
}
.list-squares > li.first {
    margin-left: -14px;
}
.list-squares > li.first:before {
    content: "";
}
.list-squares > li.first h4 {
    margin-bottom: 0;
}
.list-squares > li > ul {
    margin: 0;
    padding: 0;
    list-style: none;
    margin-top: 10px;
    margin-left: 25px;
}
.list-squares > li > ul > li:not(:last-child) {
    margin-bottom: 10px;
}
.list-squares > li > ul > li:before {
    content: '\25A0';
    display: block;
    position: relative;
    max-width: 0px;
    max-height: 0px;
    left: -14px;
    color: #c4121d;
    //font-size: 1.2rem;
    @include font-size(12px);
}
.list-squares > li > ul > li > ul {
    margin: 0;
    padding: 0;
    list-style: none;
    margin-top: 10px;
    margin-left: 25px;
}
.list-squares > li > ul > li > ul > li:not(:last-child) {
    margin-bottom: 10px;
}
.list-squares > li > ul > li > ul > li:before {
    content: '\25A0';
    display: block;
    position: relative;
    max-width: 0px;
    max-height: 0px;
    left: -14px;
    color: #c4121d;
    //font-size: 1.2rem;
    @include font-size(12px);
}
.list-dashes {
    margin: 0rem 0 25rem 14px;
    padding: 0;
    list-style: none;
}
.list-dashes h6 {
    margin: 0;
}
.list-dashes > li:not(:last-child) {
    margin-bottom: 1rem;
}
.list-dashes > li:before {
    content: "\002013";
    display: block;
    position: relative;
    max-width: 0px;
    max-height: 0px;
    left: -14px;
    color: #000000;
    //font-size: 1.6rem;
    @include font-size(16px);
}
.list-dashes > li.first {
    margin-left: -14px;
}
.list-dashes > li.first:before {
    content: "\002013";
}
.list-dashes > li.first h4 {
    margin-bottom: 0;
}
.list-dashes > li > ul {
    margin: 0;
    padding: 0;
    list-style: none;
    margin-top: 0rem;
    margin-left: 25px;
}
.list-dashes > li > ul > li:not(:last-child) {
    margin-bottom: 10px;
}
.list-dashes > li > ul > li:before {
    content: "\002013";
    display: block;
    position: relative;
    max-width: 0px;
    max-height: 0px;
    left: -14px;
    color: #000000;
    //font-size: 1.2rem;
    @include font-size(12px);
}
.list-dashes > li > ul > li > ul {
    margin: 0;
    padding: 0;
    list-style: none;
    margin-top: 0rem;
    margin-left: 25px;
}
.list-dashes > li > ul > li > ul > li:not(:last-child) {
    margin-bottom: 10px;
}
.list-dashes > li > ul > li > ul > li:before {
    content: "\002013";
    display: block;
    position: relative;
    max-width: 0px;
    max-height: 0px;
    left: -14px;
    color: #000000;
    //font-size: 1.6rem;
    @include font-size(16px);
}
.box-table {
    margin-bottom: 3rem;
}
.box-table .bt-head {
    //font-size: 1.8rem;
    @include font-size(18px);
    font-weight: bold;
    color: #002f65;
    padding: 0 10px;
    border-bottom: 3px solid #d3c094;
}
.box-table .bt-row {
    padding: 10px;
}
.box-table .bt-row.btr--shade {
    background: #f4f3ee;
}
.sm--indent {
    padding-left: 12%;
}
@media (min-width: 768px) {
    .wysiwyg .inner-block {
        padding: 20px 40px;
    }
    .wysiwyg .notes p.p--hanging {
        text-indent: -6px;
    }
    .wysiwyg .notes.notes--arrow {
        padding-left: 0;
        max-height: 100px;
        overflow: visible;
    }
    .wysiwyg .notes.notes--arrow:before {
        display: none;
    }
    .sm--indent {
        padding-left: 0;
    }
}
@media (max-width: 767px) {
    .wysiwyg a,
    .wysiwyg--fullwidth a {
        /* These are technically the same, but use both */

        overflow-wrap: break-word;
        word-wrap: break-word;
    }
}

.titleImg {
    float: left;
    margin-right: 54px;
    margin-bottom: 10px;
    max-width: 95px;
    margin-left: 24px;
    margin-top: 7px;
} 
@media (max-width: 700px) {
    .titleImg {
        display: none;
    }
}


// Legancy brought in by hand
.squared li:before {
    color: #c4121d;
    content: "\2022";
    display: block;
    @include font-size(14px);
    left: -15px;
    position: absolute;
    top: 0;
}

.squared li {
    padding-left: 4px;
    position: relative;
}
.list-dots > li:not(:last-child) {
    margin-bottom: 10px;
}

.list-square {
    margin: 0;
    padding: 0;
    list-style: none;
}
.list-square li {
    position: relative;
    padding-left: 12px;
}
.list-square li:not(:last-child) {
    margin-bottom: 15px;
    margin-top: 15px;
}
.list-square li:before {
    display: block;
    content: "\2022";
    position: absolute;
    left: 0px;
    //top: -25px;
    //font-size: 60px;
    //@include font-size(60px);
    color: #c4121d;
    line-height: normal;
}
.list-square li.m-l-3:before {
    content: "-";
}
.box-table .list-square {
    margin: 0;
    padding: 0;
    list-style: none;
}
.box-table .list-square li:not(:last-child) {
    margin-top: 0;
}
ul.list-square > li ul {
    list-style-type: none;
    padding-left: 15px;
}
ul.list-square > li ul li:before {
    content: "-";
    color: #c4121d;
    top: -5px;
    left: -6px;
    //font-size: 20px;
    @include font-size(20px);
}

/* circle callout on new pediatric page */
.responseChart {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
@media(min-width: 991px) {
	.responseChart {
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;
	}
}
.responseChart-img {
    max-width: 100%;
    width: 100%;
    float: left;
    @media(min-width: 991px) {
        max-width: 675px;
    }
}
.circleCallout {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 200px;
	height: 200px;
	margin: 40px auto;
	padding: 10px;
	background-color: #d9e0e8;
	border-radius: 50%;
	color: #002f65;
	text-align: center;
	float: left;
}

.circleCallout p {
	margin: 0;
	padding: 0;
}

.liverCallout {
	position: relative;
	display: flex;
}

.liverCallout img {
	margin-right: 20px;
	padding-right: 20px;
	border-right: 2px solid #c4121d;
}

.liverCallout p {
    //font-size: 20px;
    @include font-size(20px);
	color: #002f65;
}
.main-block.mb--tan {
    padding-top: 34px;
    padding-bottom: 34px;
}
.socPBorders {
    border-top: 4px solid #ebdbc1;
    border-bottom: 4px solid #ebdbc1;
}
.mb--blue.socPBorders {
    border-color: #033267;
}

.wysiwyg .main-block.mb--blue, .wysiwyg .main-block.mb--slate {
    background-color: #d9e0e8;
    padding: 20px 25px;
}

.headingWithIconText {
    width: calc(100% - 60px);
    margin-left: 5px;
}
.headingWithIcon, .headingWithIconText {
    display: inline-block;
    vertical-align: middle;
}

.headingWithIcon {
    width: 50px;
}
@media screen and (max-width: 992px) {
    .wysiwyg .main-block {
        padding: 20px 0;
    }
}
.liverCallout {
    position: relative;
    display: flex;
}
.liverCallout img {
    margin-right: 20px;
    padding-right: 20px;
    border-right: 2px solid #c4121d;
}
.liverCallout p {
    //font-size: 20px;
    @include font-size(20px);
    color: #002f65;
}
.row.dietaryTiles {
    margin-right: -4px;
    margin-left: -4px;
}
.dietaryTiles>[class*="col-"] {
    padding-left: 4px !important;
    padding-right: 4px !important;
    margin-bottom: 8px;
}
.mb--slate.socPBorders {
    border-color: #859cb5;
}
.list-with-icons ul {
    list-style-type: none;
    //font-size: 16px;
    @include font-size(16px);
    padding: 0;
}
.list-with-icons li {
    list-style-type: none;
    
    
    
    @include media-breakpoint-up(md) {
        display: flex;
        align-items: center;
        width: 70%;
    }
}
.list-with-icons img {
    flex: 1;
    padding-right: 24px;
}
.video-thumb {
	position: relative;
	z-index: 2;
	cursor: pointer;
}

.video-thumb:before {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	content: '';
	background: rgba(0, 0, 0, 0.25);
	z-index: 3;
	transition: .25s ease-out;
	opacity: 0;
}

.col-md-6:hover .video-thumb:before,
.video-thumb:active:before {
	opacity: 1;
}
/* align divs as tables*/

.rw-tbl table {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
}
.rw-tbl table tr td {
    padding: 0 15px;
    width: 50%;
}
.rw-tbl table tr td {
    padding: 0 15px;
    width: 50%;
}

@media (min-width: 768px) {
    .rw-tbl table tr td {
        width: 50%;
    }
    .rw-tbl {
        display: block;
    }
    .rw-nrml {
        display: none;
    }
}
@media (max-width: 767px) {
    .rw-tbl {
        display: none;
    }
    .rw-nrml {
        display: block;
    }
}
.pageIntro {
	padding-top: 60px;
}


.pageIntro h1 {
    //font-size: 36px;
    @include font-size(36px);
	font-weight: bold;
	line-height: 40px;
}

.pageIntroParagraph {
	margin-bottom: 36px;
	margin-top: 36px;
}
.subhead--footnote {
    //font-size: 12px;
    @include font-size(12px);
}

@media (min-width: 1200px) {
    .col-xl-8 {
        width: 66.6666666667%;
    }
}
.circle-callout {
    background: #d9d9d9;
    color: #002F65;
    border-radius: 50%;
    height: 180px;
    width: 180px;
    position: absolute;
    display: inline-block;
    top: 375px;
    left: 15px;
    text-align: center;
    padding: 35px 20px;
    font-weight: 500;
}


.list-with-icons ul {
	list-style-type: none;
	font-size: 16px;
	padding: 0;
}



.list-with-icons img {
	flex: 1;
    padding-right: 24px;

    flex-basis: 267px;
    width: 267px;
    height: 63px;
    @media screen and (max-width:544px) {
        display: none;
    }
}

.list-with-icons span {
	font-weight: bold;
	/* font-size: 15px; */
}

@media screen and (max-width:544px) {
	.list-with-icons ul li:before {
		content: '•';
		color: #c4121d;
		display: inline-block;
		margin-right: 8px;
		vertical-align: middle;
	}
}

.centerContent {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
}


