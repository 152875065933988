
/**
 * partials/cta
 * ---
 *
 */

 .cta-boxshadow {
    position: relative;
    
}
.cta-boxshadow a {
    position: relative;
    display: block;
    padding: 20px 25px;
    background: #fff;
    border-top: 7px solid #c4121d;
    line-height: 20px;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.25);
    transition: all 0.5s ease-in-out;
}
.cta-boxshadow a .title {
    position: relative;
    display: block;
    //font-size: 2.4rem;
    @include font-size(24px);
    
    line-height: 1;
    margin-bottom: 5px;
}
.cta-boxshadow a .title img,
.cta-boxshadow a .title svg {
    position: relative;
    right: 0;
    top: 3px;
    width: 12px;
    height: 22px;
    fill: #002f65;
    transition: all 0.5s ease-in-out;
}
.cta-boxshadow a:hover {
    color: #fff;
    background: rgba(0, 47, 101, 0.7);
    border-top: 7px solid #002f65;
    text-decoration: none;
}
.cta-boxshadow a:hover .title img,
.cta-boxshadow a:hover .title svg {
    fill: #fff;
}
.cta-boxshadow.cb--next {
    display: inline-block;
    margin-left:auto;
}
.cta-boxshadow.cb--next a {
    background: #f4f6f8;
}
.cta-boxshadow.cb--next a:hover {
    background: rgba(0, 47, 101, 0.7);
}
.cta-boxshadow.cb--side a {
    padding: 20px 20px 20px 10px;
}
.cta-boxshadow.cb--side a img,
.cta-boxshadow.cb--side a svg {
    position: absolute;
    right: 10px;
    bottom: 24px;
    width: 8px;
    height: auto;
    fill: #002f65;
    transition: all 0.5s ease-in-out;
}
.cta-boxshadow.cb--side a:hover img,
.cta-boxshadow.cb--side a:hover svg {
    fill: #fff;
}