// -----------------------------------------------------------------------------
// This file contains CSS helper classes.
// -----------------------------------------------------------------------------

//loop through each color in the $palettes map in style/scss/_variables.scss and add the modifiers
@each $palette in $palettes {
    //Grab/Define the main colors e.g. "colorBrandBlue"
    $baseColorName: nth($palette, 1);
    //Grab/Define the modifiers, e.g. "lighter"
    $modifiers: nth($palette, 2);
//Generate helper classes
@each $name, $value in $modifiers {
  //when we find the name "base" we want to remove it from the helper classes
  // i.e. good = ".colorBrandBlue", bad = ".colorBrandBlueBase"
    @if $name == base {$name : '';}
    //Generate text color classes
    .#{$baseColorName}#{capitalize($name)} {
      color: $value !important;
    }
    //Generate text color classes
    .fill#{capitalize($baseColorName)}#{capitalize($name)} {
      background-color: $value !important;
      &, a:not(.btn), a:not(.btn):hover {
          color: color-contrast($value);
      }
    }
  }
}

.noScroll {
  overflow: hidden;
  height: 100%;
}
.w-full {
  width: 100%;
}
.bg-gray {
  background-color: #EBEBEB;
}

.bg-colorGray3 {
  background-color: $colorGray3;
} 

.bg-grayGradient {
  background: #ebebeb; /* Old browsers */
  background: -moz-linear-gradient(top,  #ebebeb 0%, #ffffff 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top,  #ebebeb 0%,#ffffff 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom,  #ebebeb 0%,#ffffff 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ebebeb', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */

}

.text-colorGrayDark {
  color: $colorGrayDark;
}

.color-white {
  color: $white;
}

.color-blue {
  color: $midnightBlue;
}

.color-red {
  color: $colorBrandRed;
}


.font-14 {
  font-size: 14px !important;
}
.font-18 {
  @include font-size(18px);
}
.font-20 {
  @include font-size(20px);
}
.font-22 {
  @include font-size(22px);
}
.font-md-22 {
  @include media-breakpoint-up(md) {
    @include font-size(22px);
  }
}
.font-md-16 {
  @include media-breakpoint-up(md) {
    @include font-size(16px);
  }
}
.font-12 {
  font-size: 12px !important;
}
.font-10 {
  font-size: 10px !important;
}
.font-90of100 {
  font-size: 90%;
}

.font-adelle {
  font-family: 'adelle';
}
.font-adelle-condensed {
  font-family: 'adelle-condensed';
}

.font-arial {
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif !important;
}
.borderBox {
  border: 1px solid $colorBlue2;
}

.bg-blue2 {
  background-color: $colorBlue2;
  color: $white;
}

svg.color-red {
  fill: $colorBrandRed;
}

.weight-nornal {
  font-weight: normal !important;
}
.weight-bold {
  font-weight: 700 !important;
}
.weight-400 {
  font-weight: 400 !important;
}
.weight-300 {
  font-weight: 300 !important;
}
.weight-500 {
  font-weight: 500 !important;
}
.weight-600 {
  font-weight: 600 !important;
}
.font-normal {
  font-style: normal !important;
}


.height-90 {
  height: 90px;
}

.height-full {
  height: 100%;
}

.lh-24 {
  line-height: 1.5rem;
}

.lh-22 {
  line-height: 1.375rem;
}
.lh-20 {
  line-height: 1.25rem;
}

.lh-30 {
  line-height: 1.875rem;
}
.lh-25 {
  line-height: 1.5625rem;
}



//legacy brought in
.m-l-3 {
  margin-left: 30px !important;
}
.m-b-3 {
  margin-bottom: 30px !important;
}
.size--12 {
  //font-size: 1.2rem;
  @include font-size(12px);
}
.np-red-txt {
  color: #c4121d;
}
@media (max-width: 991px) {
  .hidden-md-down {
    display: none !important;
  }
}
@media (max-width: 767px) {
  .rw-tbl {
    display: none;
  }
}
@media (max-width: 767px) {
  .rw-nrml {
    display: block;
}
}
@media (min-width: 992px) {
  .hidden-lg-up {
    display: none !important;
  }
}

@media (min-width: 768px) {
  .rw-nrml {
    display: none;
  }
}
@media (min-width: 768px) {
  .rw-tbl {
    display: block;
  }
}
@media (min-width: 768px) {
  .hidden-md-up {
    display: none !important;
  }
}
.m-b-4 {
  margin-bottom: 40px !important;
}
@media (min-width: 992px) {
  .mt-lg-5 {
    margin-top: 50px !important;
  }
}
.m-b-2 {
  margin-bottom: 1.5rem !important;
}

.text--right {
    text-align: right;
}

.verticalAlign {
  display: block;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.m-b-1 {
  margin-bottom: 10px !important;
}

.wysiwyg .main-block.mb--blue *:last-child, .wysiwyg .main-block.mb--slate *:last-child {
  margin-bottom: 0;
}

@media (max-width: 543px) {
  .hidden-xs-down {
    display: none !important;
  }
}
@media (max-width: 767px) {
  .hidden-sm-down {
    display: none !important;
  }
}

img.mw-100 {
	max-width: 100%;
}

.wysiwyg .main-block.main-block--pb-2px {
	padding-bottom: 2px;
	// if needed for mobile:
	// @media (max-width: 767px) {
	// 	margin-bottom: -10px;
	// }
}



.underline {
  text-decoration: underline;
}

.underline:hover {
	text-decoration: underline;
}

.footnote--hanging::first-line {
	text-indent: -6px;
}

.fake-bullet .bullet.bullet-new-width {
	width: 19px;
}

.hide-mobile {
	@media (max-width: 767px) {
		display: none !important;
		visibility: hidden;
	}
}

.mb-mobile-0 {
	margin-bottom: 0;
}

.gotham {
  font-family: "Gotham A", "Gotham B", sans-serif !important;
}

.color-white {
  color: $white !important;
}

.img-float--left {
	display: block;
	margin: 0 auto 16px;
	@include media-breakpoint-up(md) {
		float: left;
		margin-right: 46px;
	}
}

.title--transform-left {
	@include media-breakpoint-up(md) {
		transform: translateX(-18px);
	}
}

.list-dots>li>ul.ul-sub>li:before {
	content: '\2013';
	color: #C4121D;
	left: -18px;
	font-weight: 700;
	top: -4px;
}

.color-black {
	color: #000;
}

.img-text-flex {
	display: flex;
	align-items: flex-start;
	img {
		margin-right: 1rem;
		min-width: 120px;
		height: unset !important;
		@include media-breakpoint-up(sm) {
			min-width: 180px;
		}
	}
}

span.br-mobile {
	display: block;
	@include media-breakpoint-up(sm) {
		display: none;
	}
}

.img--100 {
	width: 100%;
}

.text-underline {
	text-decoration: underline;
}

sup.tip {
	font-style: normal;
}

.mt-neg10 {
	margin-top: -10px;
}

.ls-med {
	letter-spacing: 0.5px;
}

.lh-20 {
	line-height: 20px;
}

// Below used to force five-point asterisks
// -- Gotham typeface has six-point asterisks, 
// which was deemed unacceptable
span.ast-five {
	font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
}

.sup-md {
	font-size: 160%;
	top: 0.1em;
}