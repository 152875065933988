.tabs {

}

.tab {
    
}
.tab-title {
	cursor: pointer;
	&:not(.inactive) {
		text-decoration: underline;
	}
	&#tab-a {
		text-decoration: underline;
		&.inactive {
			text-decoration: none !important;
		}
	}
}


.tabs-style-1 {
    .tabs {
    }
    .tab {
        position: relative;
        &.inactive {
            .arrow {
                background-color: $midnightBlue;
                &:after {
                    content: "\f067";
                }
            }
        }
    }
    .tab-title {
        background-color:#808892;
        font-weight: bold;
        padding: 0.5rem;
        text-decoration: none;
        position: relative;
        padding: 15px 46px 15px 10px;
        font-size: 15px;
        text-transform: uppercase;
        color: $white;
        &.inactive {
            color: $midnightBlue;
            background-color: #d9d9d9;
        }
    }
    .tabContent {
        border: 1px solid #808892;
    }
    .arrow {
        svg {
            display: none;
        }
        color: $white;
        background: #c4121d;
        border-radius: 0 ;
        display: block;
        height: 100% ;
        left: auto ;
        position: absolute ;
        right: 0 ;
        text-align: center;
        top: 0 ;
        -ms-transform: none ;
        transform: none ;
        width: 38px ;
        &:after {
            content: "\f068";
            color: #FFF;
            display: block;
            font-family: FontAwesome;
            font-style: normal;
            font-weight: normal;
            height: auto;
            position: relative;
            text-align: center;
            width: 100%;
            position: relative;
            top: 50%;
            -ms-transform: translateY(-50%);
            transform: translateY(-50%);
        }
    }
    

}

@include media-breakpoint-up(lg) {

    .tabs-style-1 {
        .tabs {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        .tab {
            flex: 1;
            position: relative;
            margin-left: 8px;
            &:first-child {
                margin-left: 0;
            }
            &:after {
                display: block;
                content: "";
                position: absolute;
                left: 0;
                bottom: -9px;
                width: 100%;
                height: 9px;
                box-sizing: border-box;
                background: #fff;
                border-left: 1px solid #808892;
                border-right: 1px solid #808892;
                border-bottom: 1px solid #fff;
                z-index: 1;
                bottom: -31px;
                height: 31px;
            }
            &.inactive {
                &:after {
                    display: none;
                }
                .arrow {
                    transform: rotate(0deg);
                    width: 66px;
                    height: 57px;
                    left: 0;
                    top: 0;
                    background-color: $midnightBlue;
                }
            }
    
        }
        .tab-title { 
            padding: 18px 10px 18px 80px;
            font-size: 18px;
            text-transform: none;
            &.inactive {
                border-bottom: 1px solid $colorGray;
                    &:after {
                        transform: rotate(0deg);
                    }
            }

        }
        .tab-title--small {
            font-size: 16px;
            padding: 8px 10px 10px 80px;
        }
        .arrow {
            display: block;
            transform: rotate(90deg);
            width: 88px;
            left: -16px;
            top: 16px;
            background: #c4121d;
            height: 56px;
            border-radius: 0 33px 33px 0;
            position: absolute;
            transition: all 0.5s ease-in-out;
            z-index: 2;
            svg {
                display: block;
                right: 14px;
                top: 14px;
                width: 16px;
                height: 28px;
                position: absolute;
                fill: #fff;
                transition: all 0.5s ease-in-out;
            }
            &:after {
                display: none;
            }
        }
        .tabsContent {
            margin-top: 29px;
        }
        
    }
  
}
