.headerUtility-search {
    align-items: center;
    display: flex;
    position: relative;
    margin-left: 8px;
    .headerUtility-searchInput {
        appearance: none;
        @include font-size(13px);
        opacity: 0;
        position: absolute;
        right: -10px;
        transition: width 250ms ease, opacity 250ms linear;
        width: 0;
        outline: 0;
        border: 0;
        border-radius: 6px;
        box-sizing: border-box !important;
        display: block;
        height: 30px;
    }
    .headerUtility-searchToggle {
        appearance: none;
        background: none;
        border: none;
        color: $white;
        @include font-size(20px);
        margin: 0;
        padding: 0;
        position: relative;
    }
    &.is-open {
        .headerUtility-searchInput {
            opacity: 1;
            transition: width 250ms ease, opacity 0ms linear;
            border: 1px solid #fff;
            display: block;
            padding-left: 10px;
            padding-right: 10px;
            width: 215px;
            
        }
        .headerUtility-searchToggle {
            color: $colorBrandRed;
        }
    }
}