.table-with-headers {
    text-align: center;
    font-weight: 600;
    //font-size: 1.4rem;
    @include font-size(14px);
    margin: 0 15px 30px;
}
.table-with-headers .row {
    border-bottom: 1px solid black;
}
.table-with-headers .row.header {
    color: #ffffff;
    font-weight: 600;
}
.table-with-headers .row.header .col-xs-6,
.table-with-headers .row.header .col-xs-8,
.table-with-headers .row.header .col-xs-4 {
    padding: 1.5rem 0;
}
.table-with-headers .row.header .right {
    border-left: 1px solid #ffffff;
}
.table-with-headers .row.header .left {
    text-align: center;
    font-weight: 600;
    //font-size: 1.4rem;
    @include font-size(14px);
}
.table-with-headers .row .right {
    border-left: 1px solid black;
    padding: 5px 0;
}
.table-with-headers .row .left {
    text-align: left;
    //font-size: 1.8rem;
    @include font-size(18px);
    font-weight: 400;
    padding-top: 10px;
}
.table-with-headers .row .strong {
    display: block;
    //font-size: 1.8rem;
    @include font-size(18px);
}
.table-with-headers .blue-background {
    background: #002F65;
}
.table-with-headers .red-background {
    background: #C4121D;
}
.table-with-headers .beige-background {
    background: #e0e0e0;
}
.table-with-headers .row .left {
	padding-top: 5px;
	padding-bottom: 5px;
}

.table-with-headers .row {
	display: flex;
	align-items: center;
}

.table-with-headers .table-header {
	height: 46px;
}

@media screen and (max-width:465px) {
    .table-with-headers .table-header {
        height: 56px;
    }
}

.table-style-1 {
    width: 100%;
    th {
        background-color: #4F587E;
        color: $white;
        padding: .5rem;
        font-weight: normal;
        font-size: 14px;
        padding-top: .75rem;
        padding-bottom: .75rem;
        letter-spacing: 0.075rem;
    }
    td {
        padding: .5rem;
        font-family: 'adelle';
    }

    tr:nth-child(odd){
        background-color: #E5E5E5;
    }
}