/* **************************************************************
  _____            _                        _                  _ _   _
 |  __ \          (_)                 /\   | |                (_) | | |
 | |  | | ___  ___ _ _ __   __ _     /  \  | | __ _  ___  _ __ _| |_| |__  _ __ ___
 | |  | |/ _ \/ __| | '_ \ / _` |   / /\ \ | |/ _` |/ _ \| '__| | __| '_ \| '_ ` _ \
 | |__| | (_) \__ \ | | | | (_| |  / ____ \| | (_| | (_) | |  | | |_| | | | | | | | |
 |_____/ \___/|___/_|_| |_|\__, | /_/    \_\_|\__, |\___/|_|  |_|\__|_| |_|_| |_| |_|
                            __/ |              __/ |
                           |___/              |___/
************************************************************** */
.dosing_algorithm {
    display: flex;
    flex-direction: column;
    border-top: 1px solid #d3c094;
    border-bottom: 1px solid #d3c094;
    padding: 7px 0;
    margin-top: 8px;
    margin-bottom: 50px;
}

/******************************************************************/

.dosing_algorithm .dosing_algorithm_row {
    width: 100%;
    display: flex;
    position: relative;
}

/******************************************************************/

.dosing_algorithm .dosing_algorithm_row:not(:last-child):after {
    position: absolute;
    bottom: 0;
    left: 15px;
    right: 0;
    width: auto;
    content: '';
    height: 1px;
    background: #d3c094;
}

/******************************************************************/

.dosing_algorithm .dosing_algorithm_icon {
    background: #f9f3e9;
    padding: 20px;
    flex: 0 0 110px;
    max-width: 110px;
    min-width: 110px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
}

@media (min-width: 550px) {
    .dosing_algorithm .dosing_algorithm_icon {
        flex: 0 0 140px;
        max-width: 140px;
        min-width: 140px;
    }

    .dosing_algorithm .dosing_algorithm_icon.center {
        justify-content: center;
    }
}

/******************************************************************/

.dosing_algorithm .dosing_algorithm_icon span {
    display: block;
    margin-bottom: 15px;
    font-weight: bold;
    text-transform: uppercase;
    color: #c4121d;
    font-size: 20px;
}

.dosing_algorithm .dosing_algorithm_icon span.tight {
    margin-bottom: 5px;
}

@media (min-width: 550px) {
    .dosing_algorithm .dosing_algorithm_icon span {
        display: none;
    }
}

/******************************************************************/

.dosing_algorithm .dosing_algorithm_content {
    flex: 1 1 auto;
    padding: 20px;
    padding-left: 10px;
    display: flex;
    flex-direction: column;
    width: 100%;
}

@media (min-width: 550px) {
    .dosing_algorithm .dosing_algorithm_content {
        padding-left: 50px;
    }
}

/******************************************************************/

.dosing_algorithm .dosing_algorithm_title {
    margin-bottom: 10px;
    display: none;
}

@media (min-width: 550px) {
    .dosing_algorithm .dosing_algorithm_title {
        display: block;
    }
}

/******************************************************************/

.dosing_algorithm .dosing_algorithm_title span {
    font-weight: bold;
    text-transform: uppercase;
    color: #c4121d;
    font-size: 22px;
}

/******************************************************************/

.dosing_algorithm .dosing_algorithm_copy {
    margin-bottom: 10px;
}

/******************************************************************/

.dosing_algorithm .dosing_algorithm_copy span {
    color: #002f65;
    font-size: 16px;
    text-shadow: 0.25px 0 0 #002f65;
    letter-spacing: .25px;
}

@media (min-width: 550px) {
    .dosing_algorithm .dosing_algorithm_copy span {
        font-size: 16px;
    }
}

/******************************************************************/

.dosing_algorithm .dosing_algorithm_list {
    //margin-left: -50px;
}

@media (min-width: 550px) {
    .dosing_algorithm .dosing_algorithm_list {
        margin-left: 0;
    }
}

/******************************************************************/

.dosing_algorithm .dosing_algorithm_list ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

/******************************************************************/

.dosing_algorithm .dosing_algorithm_list li {
    margin-bottom: 10px;
    display: flex;
    align-items: flex-start;
}

@media (min-width: 550px) {
    .dosing_algorithm .dosing_algorithm_list li {
        margin-bottom: 15px;
        align-items: center;
        max-width: 90%;
    }
}

/******************************************************************/

.dosing_algorithm .dosing_algorithm_list li div {
    width: 25px;
    height: 25px;
    min-width: 25px;
    min-height: 25px;
    max-width: 25px;
    max-height: 25px;
    margin-right: 15px;
    padding: 5px;
    border: 1px solid #d3c094;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.dosing_algorithm .dosing_algorithm_list li div.tight {
    padding: 2.5px;
}

@media (min-width: 550px) {
    .dosing_algorithm .dosing_algorithm_list li div {
        width: 25px;
        height: 25px;
        padding: 0;
        border: none;
    }

    .dosing_algorithm .dosing_algorithm_list li div.tight {
        padding: 0;
    }
}

/******************************************************************/

.dosing_algorithm .dosing_algorithm_list li div:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 100%;
    height: 1px;
    width: 16px;
    background: #d3c094;
    margin: auto 0;
}

@media (min-width: 550px) {
    .dosing_algorithm .dosing_algorithm_list li div:before {
        display: none;
    }
}

/******************************************************************/

.dosing_algorithm .dosing_algorithm_list li div img {
    max-width: 90%;
    height: auto;
}

@media (min-width: 550px) {
    .dosing_algorithm .dosing_algorithm_list li div img {
        max-width: 100%;
    }
}

/******************************************************************/

.dosing_algorithm .dosing_algorithm_list li span {
    color: #002f65;
    font-size: 16px;
    padding-left: 10px;
    display: block;
    text-shadow: 0.25px 0 0 #002f65;
    letter-spacing: .25px;
    line-height: 1.4;
}

@media (min-width: 550px) {
    .dosing_algorithm .dosing_algorithm_list li span {
        padding-left: 0;
    }
}

/******************************************************************/

a[href*='tel:']:focus {
    color: #002f65;
    outline: none;
    -webkit-tap-highlight-color: #002f65 !important;
}


.dosing-calc-unsupported {
    background-color: #f9f3e9;
    padding: 25px;
    border-radius: 4px;
    text-align: center;
}

.dosing-calc-unsupported p {
    color: #C4121D;
    margin: 100px 0;
}