span.jumpbar--cta {
	flex-grow: 1;
	color: #0E2E60;
}

a.jumpbar--link {
	flex-grow: 1;
	color: #B42B28;
	&--last {
		max-width: max-content;
		margin-right: .5rem;
	}
	@media (max-width: 1000px) {
		font-size: 0.875rem;
		svg {
			height: 0.625rem;
		}
		&--last {
			margin-right: .25rem;
		}
	}
	@media (max-width: 860px) {
		font-size: 0.75rem;
		svg {
			height: 0.5rem;
		} 
	}
}

a.jumpbar--link:hover {
	color: #002f65;
	cursor: pointer;
}

a.jumpbar--link .arrow {
	transform: rotate(90deg);
	display: inline-block;
	margin-left: 5px;
	color: #002f65;
}

a.jumpbar--link .arrow path {
	fill: #002f65;
}

a.jumpbar--link:hover .arrow path {
	fill: #c4121d;
}

.jumpbar {
	width: 100%;
	display: flex;
	background-color: #F4F6F7;
	border-radius: 4px;
	box-shadow: 0 2px 3px rgba(0, 60, 100, .2);
	margin-bottom: 30px;
	padding: 8px 10px;
	color: #0E2E60;
}

@media screen and (max-width: 767px) {
	.jumpbar {
		flex-direction: column;
	}

	a.jumpbar--link {
		flex: 0 0 100%;
		font-size: 18px;
		padding: 10px 10px 10px 0;
	}

	a.jumpbar--link .arrow {
		float: right;
	}

	a.jumpbar--link .arrow svg {
		display: block;
		width: 1.25rem;
		height: auto;
	}
}